import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Gender, OnboardingStatus } from '@suzy/shared/data-access/suzy-sdk';
import {
  ISignupUser,
  SignupService
} from '../../../../views/anonymous/signup/signup.service';
import swal from 'sweetalert2';
import {
  AuthenticationService,
  UserProfileService
} from '@suzy/crowdtap/data-access/user';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import {
  CampaignKeysName,
  PanelKeysName,
  PromoKeysName,
  ReferralKeysName,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { GrecaptchaService } from '../../../../core/services/grecaptcha.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import moment from 'moment';

@Component({
  selector: 'almost-there',
  templateUrl: './almost-there.component.html',
  styleUrls: ['./almost-there.component.scss']
})
export class AlmostThereComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  submitted = false;
  Gender = Gender;

  states: Array<any>;
  stateOptions = [];
  user: ISignupUser;
  sourceUrl: string;
  submitting = false;
  currentSource: any;
  isLoading: boolean;
  countries: Array<any>;
  incomeLevelOptions: Array<any>;
  currentPanelKeys: any;
  currentPromoKeys: any;
  currentPanelToken: any;
  recaptchaToken: string;
  currentPromoToken: any;
  el_wrapper: HTMLElement;
  isFbRegistered: boolean;
  currentCampaignKeys: any;
  currentReferralKeys: any;
  currentReferralToken: any;
  almostThereForm: UntypedFormGroup;
  skipLinkPathFooter: string;
  skipLinkPathContent: string;
  panelKeysName: PanelKeysName;
  campaignKeysName: CampaignKeysName;
  referralKeysName: ReferralKeysName;
  promoKeysName: PromoKeysName;
  minBirthdate: NgbDateStruct = { year: 1910, month: 1, day: 1 };
  isNewSignupQuestions = false;
  isSmsFirstFlowSignup = false;
  hideBackButton?: boolean;

  private storage: Storage;

  constructor(
    private fb: UntypedFormBuilder,
    private signupService: SignupService,
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private gtm: Angulartics2GoogleTagManager,
    private grecaptchaService: GrecaptchaService,
    private segmentService: SegmentService,
    private launchDarklyService: LaunchDarklyService,
    private userProfileService: UserProfileService
  ) {}

  ngOnInit(): void {
    this.storage = localStorage;
    this.user = this.signupService.getUser();
    if (
      this.user.facebook_access_token === '' ||
      this.user.facebook_access_token === undefined
    ) {
      this.isFbRegistered = false;
    } else {
      this.isFbRegistered = true;
    }

    this.route.data.subscribe(data => {
      this.incomeLevelOptions = data.incomeLevelOptions;
      this.stateOptions = data.states;
    });

    this.isNewSignupQuestions =
      this.launchDarklyService.getCWDT2172SignUpQuestionsFlag();
    this.isSmsFirstFlowSignup =
      this.launchDarklyService.getCWDT2328MoveSMSQuestionFlag();
    this.hideBackButton = this.isSmsFirstFlowSignup;
    this.createForm();
    this.checkCampaignKeys();
    this.checkPanelToken();
    this.checkReferralToken();
    this.checkPromoToken();
    this.grecaptchaService.execute('signup', token => {
      this.recaptchaToken = token;
    });
  }

  ngAfterViewInit(): void {
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  checkCampaignKeys(): void {
    this.campaignKeysName = new CampaignKeysName();
    this.currentSource = this.authService.getLocalKey(
      this.campaignKeysName.utmSource
    );
    this.sourceUrl = this.authService.getLocalKey(this.campaignKeysName.source);

    this.currentSource = this.authService.getLocalKey(
      this.campaignKeysName.utmSource
    );

    if (!this.sourceUrl) {
      const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;

      if (snapshot.url.includes('?')) {
        this.authService.setLocalKey(
          this.campaignKeysName.source,
          snapshot.url.substring(snapshot.url.indexOf('?'))
        );
      }
    }

    if (!this.currentSource) {
      this.route.queryParams.subscribe(params => {
        if (params[this.campaignKeysName.utmSource]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentSource = params[this.campaignKeysName.utmSource];
        }
      });
    }
  }

  checkPanelToken(): void {
    this.panelKeysName = new PanelKeysName();
    this.currentPanelToken = this.authService.getLocalKey(
      this.panelKeysName.panelToken
    );

    if (!this.currentPanelToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.panelKeysName.panelToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentPanelKeys = this.authService.getCurrentPanelKeys();
        }
      });
    } else {
      this.currentPanelKeys = this.authService.getCurrentPanelKeys();
    }
  }

  checkReferralToken(): void {
    this.referralKeysName = new ReferralKeysName();
    this.currentReferralToken = this.authService.getLocalKey(
      this.referralKeysName.referralToken
    );

    if (!this.currentReferralToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.referralKeysName.referralToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentReferralKeys = this.authService.getCurrentReferralKeys();
        }
      });
    } else {
      this.currentReferralKeys = this.authService.getCurrentReferralKeys();
    }
  }

  checkPromoToken(): void {
    this.promoKeysName = new PromoKeysName();
    this.currentPromoToken = this.authService.getLocalKey(
      this.promoKeysName.promoToken
    );

    if (!this.currentPromoToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.promoKeysName.promoToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.authService.setLocalKey(prop, params[prop]);
            }
          }
          this.currentPromoKeys = this.authService.getCurrentPromoKeys();
        }
      });
    } else {
      this.currentPromoKeys = this.authService.getCurrentPromoKeys();
    }
  }

  createForm(): void {
    if (this.isSmsFirstFlowSignup) {
      this.almostThereForm = this.fb.group({
        income_level_id: ['', Validators.required],
        zip_code: ['', Validators.required],
        city: ['', Validators.required],
        state_id: ['', Validators.required]
      });
    } else if (this.isNewSignupQuestions) {
      this.almostThereForm = this.fb.group({
        income_level_id: ['', Validators.required],
        zip_code: [
          this.user.zip_code,
          [Validators.required, Validators.pattern(/^\d{5}$/)]
        ],
        city: [this.user.city, Validators.required],
        state_id: [this.user.state_id, Validators.required],
        accepted: [false, Validators.requiredTrue]
      });
    } else {
      this.almostThereForm = this.fb.group({
        zip_code: [
          this.user.zip_code,
          [Validators.required, Validators.pattern(/^\d{5}$/)]
        ],
        city: [this.user.city, Validators.required],
        state_id: [this.user.state_id, Validators.required],
        accepted: [false, Validators.requiredTrue]
      });
    }
  }

  fieldErrors(name: string): any {
    const control = this.almostThereForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  private mapSignupDataToUserProfileUpdate(data): any {
    return {
      user_profile_id: data.user_profile_id || null,
      user_id: data.user_id || null,
      ethnicity_id: data.ethnicity_id || null,
      region_id: data.region_id || null,
      state_id: data.state_id || null,
      city: data.city || null,
      zip_code: data.zip_code || null,
      birthdate: data.birthdate || null,
      profile_state: data.profile_state || 0,
      gender: data.gender || null,
      completion_percentage: data.completion_percentage || null,
      completion_missing: data.completion_missing || [],
      completion_optional_percentage:
        data.completion_optional_percentage || null,
      completion_optional_missing: data.completion_optional_missing || [],
      country_id: data.country_id || null,
      country_code: data.country_code || null,
      has_children: data.has_children || false,
      state_short: data.state_short || null,
      ethnicity_name: data.ethnicity_name || null,
      region_name: data.region_name || null,
      address1: data.address1 || null,
      address2: data.address2 || null,
      race_id: data.race_id || null,
      industry_id: data.industry_id || null,
      industry_title_id: data.industry_title_id || null,
      employment_id: data.employment_id || null,
      education_id: data.education_id || null,
      household_id: data.household_id || null,
      income_level_id: data.income_level_id || null,
      relationship_id: data.relationship_id || null,
      parenting_id: data.parenting_id || null,
      children_details: data.children_details || []
    };
  }

  checkAgeInRange(birthdate: string): boolean {
    const age = moment().diff(birthdate, 'years');
    return age >= 13 && age <= 16;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.almostThereForm.valid && !this.submitting) {
      const account = this.almostThereForm.value;
      if (this.isSmsFirstFlowSignup) {
        this.signupService.setUser({
          ...this.user,
          income_level_id: account.income_level_id,
          city: account.city,
          zip_code: account.zip_code,
          state_id: account.state_id,
          progressStatus: 100
        });
        const currentUser = this.signupService.getUser();
        const currentProfile = this.userProfileService.getCurrentUser();
        const profileUpdateValue =
          this.userProfileService.mapSignupDataToUserProfileUpdate({
            ...currentUser,
            user_profile_id: currentProfile.user_profile_id,
            user_id: currentProfile.user_id
          });
        this.isLoading = true;
        this.submitting = true;
        this.userProfileService
          .updateProfile(currentProfile.user_profile_id, profileUpdateValue)
          .subscribe(() => {
            this.authService.ensureUser(true).subscribe(() => {
              this.signupService.clearSession();
              this.router.navigate(['/dashboard']);
            });
          });
        return;
      } else if (this.isNewSignupQuestions) {
        this.signupService.setUser({
          ...this.user,
          income_level_id: account.income_level_id,
          city: account.city,
          zip_code: account.zip_code,
          state_id: account.state_id,
          progressStatus: 85
        });
      } else {
        this.signupService.setUser({
          ...this.user,
          city: account.city,
          zip_code: account.zip_code,
          state_id: account.state_id,
          progressStatus: 85
        });
      }

      this.isLoading = true;
      this.submitting = true;
      const newUser = this.prepareSave();
      if (!this.checkAgeInRange(newUser.birthdate)) {
        newUser.age_consent_given = undefined;
      }

      this.signupService.signup(newUser).subscribe(response => {
        this.isLoading = false;
        this.submitting = false;
        if (response.success) {
          this.identifySegmentUser(newUser, response);
          this.gtm.setUsername(response.item.user_id);
          const userInformation = {
            ...response.item,
            is_fb_registered: this.isFbRegistered,
            is_email_registered: !this.isFbRegistered
          };
          delete userInformation.api_key;
          delete userInformation.api_secret;
          this.gtm.eventTrack('home-register-success', {
            event: 'home-register-success',
            gtmCustom: {
              userInformation
            }
          });
          if (this.currentSource) {
            this.authService.purgeCampaignKeys();
          }

          if (response.meta && response.meta.message_kind === 'panel_notice') {
            this.storage.setItem(
              'panel_message',
              JSON.stringify(response.meta.message)
            );

            this.authService.purgePanelKeys();
          }

          this.authService.login(response.item);
          if (this.isNewSignupQuestions) {
            const profileUpdateValue = this.mapSignupDataToUserProfileUpdate({
              ...newUser,
              user_profile_id: response.item.user_profile_id,
              user_id: response.item.user_id
            });
            this.userProfileService
              .updateProfile(response.item.user_profile_id, profileUpdateValue)
              .subscribe();
          }
          if (this.launchDarklyService.getCWDT1372Flag()) {
            this.router.navigate(['/auth/set-reward-goal']);
          } else {
            this.signupService.isFirstSMSVerifyLoad = true;
            this.router.navigate(['/auth/get-verified']);
          }
        } else {
          this.gtm.eventTrack('home-register-failed', {
            event: 'home-register-failed',
            gtmCustom: {
              userEmail: newUser.email,
              errorMessage: response.message
            }
          });
          this.isLoading = false;
          this.segmentService.trackAuthError('Sign Up Error', {
            error_message: response.message,
            error_source: 'backend',
            error_ui_message: response.message,
            method: this.isFbRegistered ? 'facebook' : 'email'
          });

          this.handleServerErrors(response);
        }
      });
    }
  }

  returnToAboutYourself(): void {
    this.router.navigate(['/auth/about-yourself']);
  }

  identifySegmentUser(user: any, response: any): void {
    this.segmentService.identify(response.item.user_id, {
      email: user.email,
      birthdate: response.item.profile.birthdate,
      city: user.city,
      gender_name: user.gender === '2' ? 'male' : 'female',
      has_profile_photo: false,
      postal_code: user.zip_code,
      state: response.item.profile.state_short,
      first_name: user.first_name,
      last_name: user.last_name,
      sign_up_method: this.isFbRegistered ? 'facebook' : 'email'
    });
    this.segmentService.track('Signed Up');
  }

  getOnboardingStatus(status: number): string {
    switch (status) {
      case OnboardingStatus.completed:
        return 'Completed';
      case OnboardingStatus.grandfathered:
        return 'Grandfathered';
      case OnboardingStatus.onboarding:
        return 'Onboarding';
      case OnboardingStatus.trapped:
        return 'Trapped';
      default:
        return 'Unknown';
    }
  }

  prepareSave(): ISignupUser {
    const newUser = this.almostThereForm.value;
    if (this.currentSource) {
      this.currentCampaignKeys = this.authService.getCurrentCampaignKeys();
      newUser.source = this.currentCampaignKeys.source;
      newUser.c_source = this.currentCampaignKeys.utmSource;
      newUser.c_medium = this.currentCampaignKeys.utmMedium;
      newUser.c_name = this.currentCampaignKeys.utmName;
      newUser.c_terms = this.currentCampaignKeys.utmTerm;
      newUser.c_content = this.currentCampaignKeys.utmContent;
      newUser.c_misc_one = this.currentCampaignKeys.miscOne;
      newUser.c_misc_two = this.currentCampaignKeys.miscTwo;
    }

    if (this.currentPanelToken) {
      this.currentPanelKeys = this.authService.getCurrentPanelKeys();
      newUser.panel_token = this.currentPanelKeys.panelToken;
    }

    if (this.currentReferralToken) {
      this.currentReferralKeys = this.authService.getCurrentReferralKeys();
      newUser.referral_token = this.currentReferralKeys.referralToken;
    }

    if (this.currentPromoToken) {
      this.currentPromoKeys = this.authService.getCurrentPromoKeys();
      newUser.promotion_token = this.currentPromoKeys.promoToken;
    }

    newUser.recaptcha_token = this.recaptchaToken;

    this.signupService.setUser(newUser);

    return this.signupService.getUser();
  }

  handleServerErrors(response): void {
    if (response.errors) {
      const serverErrors = Object.values(response.errors) as Array<string>;
      let htmlContent: string;
      if (serverErrors.length === 1) {
        htmlContent = serverErrors[0];
      } else if (serverErrors.length > 1) {
        htmlContent = '<ul style="list-style:circle;text-align:left">';
        serverErrors.forEach(error => {
          htmlContent += `<li>${error}</li>`;
        });
        htmlContent += '</ul>';
      }

      if (serverErrors.length > 0) {
        swal.fire({
          buttonsStyling: false,
          confirmButtonText: 'Continue',
          confirmButtonClass: 'btn-confirm',
          customClass: 'general-modal',
          showConfirmButton: true,
          html: `${htmlContent}`,
          title: 'Error',
          type: 'warning'
        });
      }
    } else {
      swal.fire({
        buttonsStyling: false,
        confirmButtonText: 'Continue',
        confirmButtonClass: 'btn-confirm',
        customClass: 'general-modal',
        showConfirmButton: true,
        text: response.message,
        title: 'Error',
        type: 'warning'
      });
    }
  }
}
