import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isAlertOpen = false;
  constructor(private auth: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.auth.logout(false, false, '/auth/login');
        } else if (err.status === 500 && !this.isAlertOpen) {
          swal.fire({
            buttonsStyling: false,
            confirmButtonText: 'Continue',
            confirmButtonClass: 'btn-confirm',
            customClass: 'general-modal',
            showConfirmButton: true,
            title: 'Something went wrong',
            text: 'Please try again later',
            type: 'error'
          });
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
