import { Component, OnInit, Renderer2, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionsService } from '../../../actions/actions.service';
import { PreviewService } from '../../../preview/preview.service';
import { SettingsService } from 'apps/crowdtap/src/app/core/services/settings.service';

@Component({
  selector: 'app-external-ending',
  templateUrl: './external-ending.component.html',
  styleUrls: ['./external-ending.component.scss']
})
export class ExternalEndingComponent implements OnInit, OnDestroy {
  @Input() title: string;
  description: string;
  mission: any;
  kind: number;
  action: any;
  isLoading = false;
  actionService: ActionsService;
  modalSettings: any;
  logoPath: string;
  redirectUrl: string;
  isPreview: boolean;
  isExternal: boolean;
  redirectEvent: any;

  constructor(
    private renderer: Renderer2,
    private previewService: PreviewService,
    private modal: NgbActiveModal,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.previewService.disablePreviewControls = false;
    this.renderer.addClass(document.body, 'isActionModal');
    this.isPreview = this.settingsService.isPreviewApp();
    this.isExternal = this.settingsService.isExternalApp();

    if ((this.isExternal || this.isPreview) && this.redirectUrl) {
      this.redirectEvent = setTimeout(() => {
        window.location.replace(this.redirectUrl);
      }, 5000);
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.redirectEvent);
  }

  onRestartSurvey(): void {
    this.isLoading = true;
    this.modal.dismiss('refresh');
  }
}
