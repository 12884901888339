import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';

import { Injectable } from '@angular/core';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { OnboardingStatus } from '@asksuzy/typescript-sdk';
import * as moment from 'moment';

@Injectable()
export class VerifyGuard implements CanActivate {
  isMobile: boolean;
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.isMobile = this.auth.getLocalKey('isMobile');

    return this.auth.ensureUser().pipe(
      map(res => {
        const user = res;
        if (
          this.accountCreatedAfter(user.created_utc, '2018-08-20T05:00:00Z') &&
          user.phone_verified_utc === undefined
        ) {
          if (this.isMobile) {
            this.router.navigate(['/auth/sms-authentication/mobile'], {
              queryParams: { returning: true }
            });
          } else {
            this.router.navigate(['/auth/get-verified'], {
              queryParams: { returning: true }
            });
          }

          return false;
        } else {
          const isSmsFirstFlowSignUp =
            this.launchDarklyService.getCWDT2328MoveSMSQuestionFlag();
          if (!isSmsFirstFlowSignUp) {
            return true;
          }

          if (this.hasMissingProfile(user)) {
            const signupUrl = this.getSignupStepUrl(user);
            if (signupUrl) {
              this.redirectToSignup(user, signupUrl);
              return false;
            }
          }

          return true;
        }
      }),
      take(1)
    );
  }

  accountCreatedAfter(created: string, goal: string): boolean {
    const difference = new Date(goal).getTime() - new Date(created).getTime();

    return difference < 0;
  }

  private hasMissingProfile(user: any): boolean {
    let missing = <string[]>user.profile?.completion_missing ?? [];
    missing = missing.concat(user.profile?.completion_optional_missing ?? []);
    return (
      (missing.some(x => x === 'birthdate') ||
        missing.some(x => x === 'employment') ||
        missing.some(x => x === 'city')) &&
      user.onboarding_status === OnboardingStatus.onboarding
    );
  }

  private getSignupStepUrl(user: any): string {
    let missing = <string[]>user.profile?.completion_missing ?? [];
    missing = missing.concat(user.profile?.completion_optional_missing ?? []);
    if (missing.some(x => x === 'birthdate')) {
      return '/auth/get-to-know-you';
    } else if (missing.some(x => x === 'employment')) {
      return '/auth/about-yourself';
    } else if (missing.some(x => x === 'city')) {
      return '/auth/almost-there';
    }

    return '';
  }

  private redirectToSignup(user: any, signupUrl: string): void {
    const signupUser = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      age_consent_given: true,
      c_terms: true,
      gender: user.profile?.gender,
      city: user.profile?.city,
      state_id: user.profile?.state_id,
      zip_code: user.profile?.zip_code,
      ethnicity_id: user.profile?.ethnicity_id,
      education_id: user.profile?.education_id,
      employment_id: user.profile?.employment_id,
      income_level_id: user.profile?.income_level_id,
      household_id: user.profile?.household_id,
      parenting_id: user.profile?.parenting_id,
      relationship_id: user.profile?.relationship_id,
      birthdate: ''
    };

    if (user.profile.birthdate) {
      signupUser.birthdate = moment(user.profile.birthdate).format(
        'YYYY-MM-DD'
      );
    }

    sessionStorage.setItem('signupUser', JSON.stringify(signupUser));
    this.router.navigate([signupUrl]);
  }
}
