<form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm" class="h-full">
  <!-- Form Content Starts -->
  <div class="py-4 pt-10 form-group form-filling bg-box-light dark:bg-box-dark">
    <div class="form-field-container">
      <!-- begin desktop -->
      <div class="hide-mobile desktop">
        <div
          class="answer-box border-body-dark dark:border-body-light pl-14 ltr:pl-14 rtl:pr-14"
        >
          <p
            class="font-semibold text-body-dark dark:text-body-light text-sx md:text-sm"
          >
            {{ lowestTerminalValue }}
          </p>
          <div
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            class="example-list"
            [cdkDropListSortPredicate]="sortPredicate"
          >
            <div *ngFor="let row of rows; let i = index">
              <div
                class="answer"
                [ngClass]="{
                  draggable: row[0]?.rank != undefined,
                  full: row[0]?.rank != undefined,
                  'last-drop-index':
                    row[0]?.data?.lastUpdated != -1 &&
                    row[0]?.data.lastUpdated == i &&
                    showDragLine,
                  'border-remove': isDarkMode
                }"
                (click)="selectMobileRow($event)"
                cdkDrag
                (cdkDragStarted)="cdkDragStarted(i, row)"
                (cdkDragEnded)="cdkDragEnded($event)"
                [cdkDragData]="row[0]?.data"
              >
                <div
                  (clickOutside)="isAllRowSelected = -1"
                  class="row desktop-list"
                  [ngClass]="{ 'border-remove': isDarkMode }"
                >
                  <div
                    class="is-value rounded-md ltr:pl-18 rtl:pr-18"
                    [id]="i"
                    #toggleButton
                    (click)="openMenuAll(i); $event.stopPropagation()"
                  >
                    <span
                      *ngIf="row[0]?.rank != undefined"
                      style="cursor: pointer"
                      >{{ row[0]?.rank + 1 }}</span
                    >
                  </div>
                  <ul
                    (click)="$event.stopPropagation()"
                    *ngIf="isAllRowSelected == i"
                    (click)="mobileSetSelectedRow(i); selectMobileBlur($event)"
                    class="ml-14 ltr:ml-14 rtl:mr-14"
                  >
                    <li
                      *ngFor="let rowItem of rows; let j = index"
                      [ngClass]="{ active: row[0]?.rank + 1 == j + 1 }"
                    >
                      <input
                        [id]="'l' + j + 1"
                        type="radio"
                        [value]="j"
                        name="rank2"
                        (change)="onRowRankSelected($event, row, i)"
                      />
                      <label [for]="'l' + j + 1" class="text-xl">
                        {{ j + 1 }}</label
                      >
                    </li>
                  </ul>
                </div>

                <!-- <div class="pill" [attr.data-content]="i + 1"></div> -->
                <div
                  class="bg-box-light dark:bg-box-dark answer-label rounded-md"
                  [ngClass]="{ darkMode: isDarkMode }"
                >
                  {{ row[0].row.answer_text }}
                </div>
                <div
                  class="example-custom-placeholder"
                  *cdkDragPlaceholder
                ></div>
              </div>
              <div
                *ngIf="
                  errorMessageRank &&
                  selectedRow == i &&
                  row[0]?.rank == undefined
                "
                class="text-error mb-4 flex items-center space-x-2 text-sm font-bold"
              >
                <svg
                  class="mr-2"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.50016 4.04034C8.06294 4.04034 7.7085 4.39478 7.7085 4.83201V7.99867C7.7085 8.4359 8.06294 8.79034 8.50016 8.79034C8.93739 8.79034 9.29183 8.4359 9.29183 7.99867V4.83201C9.29183 4.39478 8.93739 4.04034 8.50016 4.04034ZM9.22849 10.8646C9.21117 10.8141 9.18721 10.7662 9.15724 10.7221L9.06224 10.6033C8.83459 10.3787 8.49414 10.3131 8.19933 10.4371C8.10339 10.4771 8.01503 10.5334 7.93808 10.6033C7.78989 10.7527 7.70727 10.955 7.70849 11.1654C7.70974 11.2688 7.73126 11.371 7.77182 11.4662C7.84381 11.6651 8.00043 11.8217 8.19933 11.8937C8.39094 11.9784 8.60938 11.9784 8.80099 11.8937C8.99988 11.8217 9.15651 11.6651 9.22849 11.4662C9.26906 11.371 9.29058 11.2688 9.29183 11.1654C9.29571 11.1127 9.29571 11.0598 9.29183 11.0071C9.2782 10.9566 9.25683 10.9085 9.22849 10.8646ZM8.50016 0.0820312C4.12791 0.0820312 0.583496 3.62644 0.583496 7.9987C0.583496 12.371 4.12791 15.9154 8.50016 15.9154C12.8724 15.9154 16.4168 12.371 16.4168 7.9987C16.4168 5.89907 15.5828 3.88543 14.0981 2.40077C12.6134 0.916106 10.5998 0.0820312 8.50016 0.0820312ZM8.50017 14.332C5.00237 14.332 2.16684 11.4965 2.16684 7.99867C2.16684 4.50087 5.00237 1.66534 8.50017 1.66534C11.998 1.66534 14.8335 4.50087 14.8335 7.99867C14.8335 9.67838 14.1662 11.2893 12.9785 12.477C11.7908 13.6647 10.1799 14.332 8.50017 14.332Z"
                    fill="#FF5F57"
                  />
                </svg>
                {{ errorMessageRank }}
              </div>
            </div>
          </div>
          <p
            class="font-semibold text-body-dark dark:text-body-light text-sx md:text-sm"
            data-track="member-rank-grid-highest-terminal"
          >
            {{ highestTerminalValue }}
          </p>
          <div
            *ngIf="errorMessage"
            class="text-error flex items-center space-x-2 text-sm font-bold"
          >
            <svg
              class="mr-2"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.50016 4.04034C8.06294 4.04034 7.7085 4.39478 7.7085 4.83201V7.99867C7.7085 8.4359 8.06294 8.79034 8.50016 8.79034C8.93739 8.79034 9.29183 8.4359 9.29183 7.99867V4.83201C9.29183 4.39478 8.93739 4.04034 8.50016 4.04034ZM9.22849 10.8646C9.21117 10.8141 9.18721 10.7662 9.15724 10.7221L9.06224 10.6033C8.83459 10.3787 8.49414 10.3131 8.19933 10.4371C8.10339 10.4771 8.01503 10.5334 7.93808 10.6033C7.78989 10.7527 7.70727 10.955 7.70849 11.1654C7.70974 11.2688 7.73126 11.371 7.77182 11.4662C7.84381 11.6651 8.00043 11.8217 8.19933 11.8937C8.39094 11.9784 8.60938 11.9784 8.80099 11.8937C8.99988 11.8217 9.15651 11.6651 9.22849 11.4662C9.26906 11.371 9.29058 11.2688 9.29183 11.1654C9.29571 11.1127 9.29571 11.0598 9.29183 11.0071C9.2782 10.9566 9.25683 10.9085 9.22849 10.8646ZM8.50016 0.0820312C4.12791 0.0820312 0.583496 3.62644 0.583496 7.9987C0.583496 12.371 4.12791 15.9154 8.50016 15.9154C12.8724 15.9154 16.4168 12.371 16.4168 7.9987C16.4168 5.89907 15.5828 3.88543 14.0981 2.40077C12.6134 0.916106 10.5998 0.0820312 8.50016 0.0820312ZM8.50017 14.332C5.00237 14.332 2.16684 11.4965 2.16684 7.99867C2.16684 4.50087 5.00237 1.66534 8.50017 1.66534C11.998 1.66534 14.8335 4.50087 14.8335 7.99867C14.8335 9.67838 14.1662 11.2893 12.9785 12.477C11.7908 13.6647 10.1799 14.332 8.50017 14.332Z"
                fill="#FF5F57"
              />
            </svg>
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <!-- end desktop -->

      <!-- begin mobile -->
      <div class="show-mobile mobile" #mobile>
        <ng-container *ngIf="!allRowsSelected()">
          <div
            *ngFor="let row of fullRowAnswers; let i = index"
            (click)="selectMobileRow($event)"
            class="row chosen"
            [ngClass]="{ darkMode: isDarkMode }"
            [attr.data-content]="row.rank + 1"
            [ngClass]="{ last: i === fullRowAnswers.length - 1 }"
          >
            <select
              (change)="mobileItemSelected($event, row)"
              (blur)="selectMobileBlur($event)"
            >
              <option disabled selected value>--</option>
              <option *ngFor="let row of rows; let i = index" [value]="i">
                {{ i + 1 }}
              </option>
            </select>
            {{ row.text }}
          </div>
          <div
            *ngFor="let row of emptyRowAnswers; let i = index"
            class="row"
            [ngClass]="{ darkMode: isDarkMode }"
          >
            <select
              (focus)="selectMobileRow($event)"
              (change)="mobileItemSelected($event, row)"
              (blur)="selectMobileBlur($event)"
            >
              <option disabled selected value>--</option>
              <option *ngFor="let row of rows; let i = index" [value]="i">
                {{ i + 1 }}
              </option>
            </select>
            {{ row.text }}
          </div>
        </ng-container>
        <ng-container *ngIf="allRowsSelected()">
          <div
            *ngFor="let row of rows; let i = index"
            (click)="selectMobileRow($event)"
            class="row chosen"
            [attr.data-content]="i + 1"
            [ngClass]="{ last: i === rows.length - 1 }"
          >
            <select
              (focus)="mobileSetSelectedRow(i)"
              (change)="mobileFullItemSelected($event, i)"
              (blur)="selectMobileBlur($event)"
            >
              <option disabled selected value>--</option>
              <option *ngFor="let row of rows; let i = index" [value]="i">
                {{ i + 1 }}
              </option>
            </select>
            {{ row[0].row.answer_text }}
          </div>
        </ng-container>
      </div>
      <!-- end mobile -->
    </div>
  </div>

  <div class="flex items-center justify-center pt-4 pb-12 space-x-2">
    <div *ngIf="!disableSkip">
      <button
        class="text-sm font-bold w-36 text-blue-dark md:text-base skip ng-star-inserted"
        (click)="onSkip($event)"
        data-track="member-rank-grid-skip"
        (mousemove)="onMouseMoveEvent($event)"
        [ngClass]="{ loading: isSkipping }"
        [disabled]="isSkipping"
      >
        <span class="btn-label"> {{ 'general.skip' | translate }}</span>
        <span class="dot dot-1"></span>
        <span class="dot dot-2"></span>
        <span class="dot dot-3"></span>
      </button>
    </div>
    <div>
      <button
        class="animation-submit-btn"
        [ngClass]="{
          primarySection: !isLoading && !isSuccess,
          loadingSection: isLoading,
          successSection: isSuccess
        }"
        data-track="member-rank-grid-continue"
        (mousemove)="onMouseMoveEvent($event)"
        [disabled]="isSkipping || isSubmitting || isLoading"
      >
        <div class="primaryTitle text submit-label">
          {{ 'general.submit' | translate }}
        </div>
        <div class="primaryTitle text continue-label">
          {{ 'general.continue' | translate }}
        </div>
        <div class="loadingTitle text">
          <suzy-spinner
            class="absolute left-0 right-0 button-spinner top-3"
            size="small"
            button="true"
            [round]="7"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
        </div>
        <div class="successTitle text">
          <svg class="animated-check" viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
          </svg>
        </div>
      </button>
    </div>
  </div>
  <!-- Form Content Ends -->
</form>
