import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/**
 * Represents a request to update account information associated
 * with the current Verisoul session.
 */
export interface AccountUpdateRequest {
  id: string;
  email: string;
}

/**
 * Represents the Verisoul Browser SDK, contained within the global window object.
 */
export interface VerisoulSdk {
  /**
   * Gets a promise that resolves once Verisoul collects a minimum amount of
   * session information to make a prediction.
   *
   * @returns A promise that resolves with the current session ID once Verisoul has
   *         collected enough information to make a prediction.
   */
  session: () => Promise<{ session_id: string }>;

  /**
   * Reinitializes the Verisoul SDK, clearing the current session.
   *
   * @returns A promise that resolves once the Verisoul SDK has been reinitialized.
   */
  reinitialize: () => Promise<void>;
}

/**
 * The Verisoul service is responsible for initializing the Verisoul SDK and
 * establishing a session on login and clearing the session on logout.
 */
@Injectable({
  providedIn: 'root'
})
export class VerisoulService {
  /** This subject is used to ensure the Verisoul SDK is only accessed once loaded */
  private isLoaded$: Promise<boolean>;
  private loadingComplete$: (value: boolean | PromiseLike<boolean>) => void;

  /** This subject contains the most recent Verisoul session ID */
  private sessionId$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.isLoaded$ = new Promise<boolean>(resolve => {
      this.loadingComplete$ = resolve;
    });
  }

  /**
   * Initializes the Verisoul Service.
   */
  initialize(verisoulBundleUri: string, verisoulProjectId: string) {
    // Verisoul recommends their script be loaded asynchronously
    if (this.document.getElementById('verisoul-snippet')) {
      this.loadingComplete$(true);
    } else {
      let script = this.document.createElement('script');
      script.id = 'verisoul-snippet';
      script.type = 'text/javascript';
      script.src = verisoulBundleUri;
      script.setAttribute('verisoul-project-id', verisoulProjectId);
      script.onload = () => {
        console.debug('Verisoul SDK loaded');
        this.loadingComplete$(true);
      };
      script.onerror = (e: any) => {
        console.error('Verisoul SDK load failed', e);
        this.loadingComplete$(false);
      };
      this.document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  /**
   * Get the current Verisoul session ID.
   * @returns The current Verisoul session ID, or undefined if not available.
   */
  getSessionId(): string | undefined {
    return this.sessionId$.getValue();
  }

  /**
   * Call this method when a user logs in to establish a session with Verisoul.
   * @param [reinitialize=false] If true, the Verisoul SDK will be reinitialized before getting the session ID.
   * @returns An observable that emits the session ID once it is available.
   */
  onLogin(reinitialize: boolean = false): Subject<string | undefined> {
    // https://docs.verisoul.ai/docs/get-session-and-reinitialize
    this.isLoaded$.then(async () => {
      if (window === undefined || window['Verisoul'] === undefined) {
        return;
      }

      const verisoulSdk = window['Verisoul'] as VerisoulSdk;

      if (reinitialize) {
        await this.reinitializeAsync(verisoulSdk);
      }

      try {
        const { session_id } = await verisoulSdk.session();
        this.sessionId$.next(session_id);
      } catch (e) {
        console.debug('Error getting session id from Verisoul', e);
        this.sessionId$.next(undefined);
      }
    });

    return this.sessionId$;
  }

  /**
   * Call this method when a user logs out to clear the session with Verisoul.
   */
  onLogout(): Promise<void> {
    // https://docs.verisoul.ai/docs/get-session-and-reinitialize
    return this.isLoaded$.then(async () => {
      if (window === undefined || window['Verisoul'] === undefined) {
        return;
      }

      const verisoulSdk = window['Verisoul'] as VerisoulSdk;

      await this.reinitializeAsync(verisoulSdk);
    });
  }

  private async reinitializeAsync(verisoulSdk: VerisoulSdk) {
    try {
      await verisoulSdk.reinitialize();
    } catch (e) {
      console.debug('Error reinitializing Verisoul', e);
    } finally {
      this.sessionId$.next(undefined);
    }
  }
}
