import { Injectable } from '@angular/core';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { segment_special_tile } from '@suzy/shared/data-access/tracking';

@Injectable({
  providedIn: 'root'
})
export class SpecialMissionService {
  constructor(private launchDarklyService: LaunchDarklyService) {
    this.launchDarklySpecialMissions =
      this.launchDarklyService.getSDOM747MakeMissionSpecialTileFlag() as unknown as launchDarklySpecialMission[];
  }
  private properties = {
    crowdtap_special_missions: [],
    crowdtap_completed_missions_daily: '0'
  };
  private launchDarklySpecialMissions: launchDarklySpecialMission[] = [];

  public get Properties() {
    return this.properties;
  }

  public set Properties(data: any) {
    this.properties.crowdtap_completed_missions_daily =
      data?.crowdtap_completed_missions_daily;
    if (data && data.crowdtap_special_missions) {
      this.properties.crowdtap_special_missions = JSON.parse(
        data.crowdtap_special_missions
      );
    }
  }

  public getLaunchDarklySpecialMissions(): launchDarklySpecialMission[] {
    return this.launchDarklySpecialMissions;
  }
}

export class launchDarklySpecialMission {
  missionId: string;
  tileType: segment_special_tile;
}
