import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, share } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { DeviceService } from '@suzy/shared/tools/device';
import { default as data } from '../../../../../../package.json';
import { SuzyRequest, SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import {
  GlobalRequest,
  GlobalIsolateSdkService
} from '@suzy/shared/data-access/global-isolate-sdk';
import { VerisoulService } from '@suzy/shared/data-access/tracking';

export interface ApiCredentials {
  api_key: string;
  api_secret: string;
}

export type SdkService = GlobalIsolateSdkService | SuzySdkService;

export type SdkRequest = GlobalRequest | SuzyRequest;

export class SdkHelper {
  sdkService: SdkService;
  httpService: HttpClient;
  deviceService: DeviceService;
  verisoulService?: VerisoulService;
  language: string;
  disableTranslations = false;

  constructor(
    sdkService: SdkService,
    httpService: HttpClient,
    deviceService: DeviceService,
    verisoulService: VerisoulService = undefined
  ) {
    this.sdkService = sdkService;
    this.httpService = httpService;
    this.deviceService = deviceService;
    this.verisoulService = verisoulService;
  }

  initialize(baseUrl: string): SdkService {
    this.sdkService.baseUrl =
      baseUrl === null || baseUrl === '' ? '/api' : baseUrl;
    this.sdkService.constructEndpoints(this.sdkService);

    return this.sdkService;
  }

  getApiCredentials(): ApiCredentials {
    return this.sdkService.apiKeys;
  }

  setApiCredentials(apiKeys: ApiCredentials): void {
    this.sdkService.apiKeys = apiKeys;
  }

  clearApiCredentials(): void {
    this.sdkService.apiKeys = undefined;
  }

  getPlatform(): string {
    let platform = 'member';
    if (this.deviceService.isMobile()) {
      platform += '-mobile';
    }

    return platform;
  }

  getVerisoulSessionId(): string | undefined {
    return this.verisoulService?.getSessionId();
  }

  getSignature(): { key: string; signature: string | Int32Array } {
    const apiKeys = this.getApiCredentials();
    if (apiKeys) {
      return {
        key: apiKeys.api_key,
        signature: Md5.hashStr(
          apiKeys.api_key + apiKeys.api_secret + Math.trunc(Date.now() / 1000)
        )
      };
    }

    return undefined;
  }

  generateHeaders(): HttpHeaders {
    const type = 'application/json';
    const platform = this.getPlatform();
    const signature = this.getSignature();
    const verisoulSessionId = this.getVerisoulSessionId();

    let headers = new HttpHeaders()
      .set('Content-Type', type)
      .set('x-device-version', data.version)
      .set('x-device-platform', platform);

    /**
     * If we have a Verisoul session ID, include it in the x-device-session-id
     * header along with a prefix to disambiguate it from other session IDs.
     */
    if (verisoulSessionId !== undefined) {
      headers = headers.append(
        'x-device-session-id',
        `verisoul:${verisoulSessionId}`
      );
    }

    if (this.language !== undefined) {
      headers = headers.append('x-language', this.language);
    }

    if (signature !== undefined) {
      headers = headers
        .append('x-api-key', signature.key)
        .append('x-api-signature', signature.signature.toString());
    }
    if (this.disableTranslations) {
      headers = headers.append('x-translations', 'disabled');
    }

    return headers;
  }

  execute(request: SdkRequest): Observable<any> {
    const self = this;
    var data;

    data = request.payload || request.params;

    if (!request.payload) {
      for (const key in data) {
        if (data[key] === null) {
          data[key] = '';
        }
      }
    }

    const promise: Observable<any> = this.httpService.request(
      new HttpRequest(
        request.method,
        `${this.sdkService.baseUrl}/${request.resource}`,
        data,
        {
          withCredentials: true,
          headers: this.generateHeaders(),
          params: new HttpParams({ fromObject: request.params })
        }
      )
    );

    return promise
      .pipe(
        filter(result => result.type === 4),
        map(result => result.body)
      )
      .pipe(catchError(this.mapError))
      .pipe(share());
  }

  setLanguage(language: string): void {
    this.language = language;
  }

  private mapError = (error: any): any => {
    if (error.error instanceof ErrorEvent) {
      return throwError(error.error.message);
    } else {
      if (error.statusText && error.statusText[0] === '{') {
        return throwError(JSON.parse(error.statusText).message);
      } else if (error.statusText) {
        const errorMessage = `${error.statusText} ${this.sdkService.defaultError}`;

        return throwError(errorMessage);
      } else if (error.error) {
        return throwError(error.error);
      } else {
        return throwError(this.sdkService.defaultError);
      }
    }
  };
}
