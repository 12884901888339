<div class="wrapper" #wrapper>
  <app-general-header viewKind="signup"></app-general-header>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <article
      class="flex flex-col w-full px-4 m-auto mt-6 max-w-auth sm:max-w-sm-auth"
    >
      <div
        *ngIf="!isNewSignupQuestions && !isSmsFirstFlowSignup"
        class="flex overflow-hidden text-xs rounded progress-bar bg-gray"
      >
        <div
          [ngStyle]="{ width: user.progressStatus + '%' }"
          class="flex flex-col justify-center text-center text-white shadow-none whitespace-nowrap bg-blue-dark"
        ></div>
      </div>
      <div class="center-column">
        <h1
          class="mt-6 mb-8 text-4xl font-bold text-center text-light-black dark:text-white sm:text-3xl"
        >
          {{ 'gettingToKnowYou.tellUsAboutYourself' | translate }}
        </h1>
        <p
          class="mt-6 mb-8 text-center subtitle text-light-black dark:text-white"
        >
          {{
            !isNewSignupQuestions && !isSmsFirstFlowSignup
              ? ('aboutYourself.aboutYourSelftDescription' | translate)
              : ('aboutYourself.tellUsAboutDescription' | translate)
          }}
        </p>

        <form [formGroup]="aboutYourSelfForm" (ngSubmit)="onSubmit()">
          <ng-container
            *ngIf="
              !isNewSignupQuestions && !isSmsFirstFlowSignup;
              else newSignUpTpl
            "
          >
            <div class="mb-6 form-row">
              <label
                for="aboutBirthday"
                class="text-light-black dark:text-white"
              >
                {{ 'profile.yourBirthday' | translate }}
              </label>

              <div #birthday class="relative mb-3 datepicker form-floating">
                <input
                  type="date"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  placeholder="MM/DD/YYYY"
                  name="dp"
                  formControlName="birthdate"
                  data-track="member-sign-up-birthday"
                />
              </div>

              <div *ngIf="showAgeVerificationCheckbox">
                <div
                  class="relative flex items-center gap-4 check-container age-confirmation-checkbox-container"
                  [class.age-checkbox-error]="fieldErrors('age_consent_given')"
                >
                  <input
                    type="checkbox"
                    class="styled-checkbox"
                    name=""
                    formControlName="age_consent_given"
                    id="check-1"
                    data-track="member-sign-up-age-consent-given"
                  />
                  <label
                    class="custom-checkbox text-light-black dark:text-white flex items-center gap-2"
                    for="check-1"
                    style="font-size: 14px"
                  >
                    {{ 'gettingToKnowYou.invalidAgeLabel' | translate }}
                  </label>
                </div>
                <small
                  *ngIf="fieldErrors('age_consent_given')"
                  class="form-text text-muted danger"
                >
                  <span *ngIf="fieldErrors('age_consent_given')?.required">
                    {{ 'gettingToKnowYou.agreeToContinue' | translate }}
                  </span>
                </small>
              </div>

              <small
                *ngIf="fieldErrors('birthdate')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('birthdate').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>

            <div #gender class="mb-6">
              <label class="text-light-black dark:text-white">
                {{ 'profile.genderPreference' | translate }}
              </label>
              <div class="custom-select">
                <select
                  formControlName="gender"
                  data-track="member-sign-up-gender"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  aria-label="Default select example"
                >
                  <option value="{{ Gender.female }}">
                    {{ 'gettingToKnowYou.female' | translate }}
                  </option>
                  <option value="{{ Gender.male }}">
                    {{ 'gettingToKnowYou.male' | translate }}
                  </option>
                </select>
              </div>
              <small
                *ngIf="fieldErrors('gender')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('gender').server">
                  {{ fieldErrors('gender').server }}
                </span>
                <span *ngIf="fieldErrors('gender').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>

            <div #race class="mb-6">
              <label class="text-light-black dark:text-white">
                {{ 'profile.yourRace' | translate }}
              </label>
              <div class="custom-select">
                <select
                  formControlName="ethnicity_id"
                  data-track="member-sign-up-ethnicity_id"
                  class="block w-full p-2 mt-2 border rounded-sm input-edge text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  aria-label="Default select example"
                >
                  <option value="">
                    {{ 'general.selectOne' | translate }}
                  </option>
                  <option
                    *ngFor="let ethnicity of ethnicities"
                    [value]="ethnicity.id"
                  >
                    {{ ethnicity.name | translate }}
                  </option>
                </select>
              </div>
              <small
                *ngIf="fieldErrors('ethnicity_id')"
                class="form-text text-muted danger"
              >
                <span *ngIf="fieldErrors('ethnicity_id').server">
                  {{ fieldErrors('ethnicity_id').server }}
                </span>
                <span *ngIf="fieldErrors('ethnicity_id').required">
                  {{ 'general.required' | translate }}
                </span>
              </small>
            </div>
          </ng-container>

          <ng-template #newSignUpTpl>
            <div class="form-row">
              <div class="inline-block w-full pr-2 mb-6 align-top">
                <div
                  class="form-group"
                  [ngClass]="{ invalid: fieldErrors('employment_id') }"
                >
                  <label class="text-light-black dark:text-white">{{
                    'profile.employmentStatus' | translate
                  }}</label>

                  <div class="custom-select">
                    <select
                      class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                      formControlName="employment_id"
                      data-track="member-profile-employment-status"
                    >
                      <option value="">
                        {{ 'general.selectOne' | translate }}
                      </option>
                      <option
                        *ngFor="let option of employmentOptions"
                        [value]="option.id"
                      >
                        {{ option.name | translate }}
                      </option>
                    </select>
                  </div>

                  <small
                    *ngIf="fieldErrors('employment_id')"
                    class="form-text text-muted danger font-semibold text-xs"
                  >
                    <span *ngIf="fieldErrors('employment_id').required">
                      {{ 'general.makeSelection' | translate }}
                    </span>
                  </small>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="inline-block w-full pr-2 mb-6 align-top">
                <div
                  class="form-group"
                  [ngClass]="{ invalid: fieldErrors('income_level_id') }"
                >
                  <label class="text-light-black dark:text-white">{{
                    'aboutYourself.relationshipStatus' | translate
                  }}</label>

                  <div class="custom-select">
                    <select
                      class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                      formControlName="relationship_id"
                      data-track="member-sign-up-relationship_id"
                    >
                      <option value="">
                        {{ 'general.selectOne' | translate }}
                      </option>
                      <option
                        *ngFor="let option of relationshipOptions"
                        [value]="option.id"
                      >
                        {{ option.name | translate }}
                      </option>
                    </select>
                  </div>

                  <small
                    *ngIf="fieldErrors('relationship_id')"
                    class="form-text text-muted danger font-semibold text-xs"
                  >
                    <span *ngIf="fieldErrors('relationship_id').required">
                      {{ 'general.makeSelection' | translate }}
                    </span>
                  </small>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="inline-block w-full pr-2 mb-6 align-top">
                <div
                  class="form-group"
                  [ngClass]="{ invalid: fieldErrors('household_id') }"
                >
                  <label class="text-light-black dark:text-white">{{
                    'profile.sizeOfHousehold' | translate
                  }}</label>

                  <div class="custom-select">
                    <select
                      class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                      formControlName="household_id"
                      data-track="member-profile-size-of-household"
                    >
                      <option value="">
                        {{ 'general.selectOne' | translate }}
                      </option>
                      <option
                        *ngFor="let option of householdOptions"
                        [value]="option.id"
                      >
                        {{ option.name | translate }}
                      </option>
                    </select>
                  </div>

                  <small
                    *ngIf="fieldErrors('household_id')"
                    class="form-text text-muted danger font-semibold text-xs"
                  >
                    <span *ngIf="fieldErrors('household_id').required">
                      {{ 'general.makeSelection' | translate }}
                    </span>
                  </small>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="inline-block w-full pr-2 mb-6 align-top">
                <div
                  class="form-group"
                  [ngClass]="{ invalid: fieldErrors('parenting_id') }"
                >
                  <label class="text-light-black dark:text-white">{{
                    'profile.parentingStatus' | translate
                  }}</label>

                  <div class="custom-select">
                    <select
                      class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                      formControlName="parenting_id"
                      data-track="member-profile-parenting-status"
                    >
                      <option value="">
                        {{ 'general.selectOne' | translate }}
                      </option>
                      <option
                        *ngFor="let option of parentingOptions"
                        [value]="option.id"
                      >
                        {{ option.name | translate }}
                      </option>
                    </select>
                  </div>

                  <small
                    *ngIf="fieldErrors('parenting_id')"
                    class="form-text text-muted danger font-semibold text-xs"
                  >
                    <span *ngIf="fieldErrors('parenting_id').required">
                      {{ 'general.makeSelection' | translate }}
                    </span>
                  </small>
                </div>
              </div>
            </div>
          </ng-template>

          <section class="flex justify-center space-x-8 text-center">
            <button
              *ngIf="!hideBackButton"
              type="button"
              class="text-black action-btn dark:text-white max-w-38"
              (click)="returnToGetToKnowYou()"
            >
              {{ 'auth.back' | translate }}
            </button>
            <button
              type="submit"
              (click)="onSubmit()"
              data-track="member-sign-up-continue"
              [class.next-button]="isNewSignupQuestions || isSmsFirstFlowSignup"
              class="relative pl-4 pr-4 font-bold text-white rounded-full bg-blue-dark h-14 action-btn"
              [disabled]="isLoading"
            >
              {{ 'general.next' | translate }}
              <div class="absolute left-8 button-spinner top-7">
                <suzy-spinner
                  *ngIf="isLoading"
                  size="small"
                  button="true"
                  [round]="isNewSignupQuestions ? '8' : '10'"
                  [color]="'#ffffff'"
                  [overlay]="false"
                >
                </suzy-spinner>
              </div>
            </button>
          </section>
        </form>
      </div>
    </article>
  </section>
  <section class="footer-contianer">
    <app-general-footer id="main-footer"></app-general-footer>
  </section>
</div>
