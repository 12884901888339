import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs/';
import { DemographicService } from '../../core/services/demographic.service';

@Injectable()
export class ParentingOptionsResolver implements Resolve<Observable<any>> {
  constructor(private demographicService: DemographicService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.demographicService.parentingFind();
  }
}
