import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbCalendarHebrew,
  NgbDatepickerI18n,
  NgbDatepickerI18nHebrew,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from '../../shared/shared.module';
import { SuccessChangedComponent } from '../anonymous/login/success-changed/success-changed.component';
import { AnonymousRoutes } from './anonymous.routing';
import { AutoLoginComponent } from './auto-login/auto-login.component';
import { GenericSharingMissionComponent } from './generic-sharing-mission/generic-sharing-mission.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { LegalComponent } from './legal/legal.component';
import { LoadingMobileComponent } from './loading-mobile/loading-mobile.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { SuccessSentComponent } from './login/success-sent/success-sent.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ReferAFriendComponent } from './refer-a-friend/refer-a-friend.component';
import { GeneralFooterComponent } from './shared/general-footer/general-footer.component';
import { GeneralRewardsComponent } from './shared/set-goal-components/rewards/rewards.component';
import { GeneralRewardsListComponent } from './shared/set-goal-components/rewards-list/rewards-list.component';
import { GeneralHeaderComponent } from './shared/general-header/general-header.component';
import { SignoutComponent } from './signout/signout.component';
import { AboutYourSelfComponent } from './signup/about-your-self/about-your-self.component';
import { AccountCreateEmailComponent } from './signup/account-create-email/account-create-email.component';
import { AccountCreationEmailComponent } from './signup/account-creation-email/account-creation-email.component';
import { AlmostThereComponent } from './signup/almost-there/almost-there.component';
import { GetToKnowYouComponent } from './signup/get-to-know-you/get-to-know-you.component';
import { GetVerifiedComponent } from './signup/get-verified/get-verified.component';
import { GettingToKnowYouComponent } from './signup/getting-to-know-you/getting-to-know-you.component';
import { SignupService } from './signup/signup.service';
import { SMSAuthenticationComponent } from './sms-authentication/sms-authentication.component';
import { SMSSuccessAnimationComponent } from './sms-authentication/sms-success-animation/sms-success-animation.component';
import { AccessDeniedComponent } from './unauthorized/access-denied/access-denied.component';
import { BlockedIPComponent } from './unauthorized/blocked-ip/blocked-ip.component';
import { CountryNotSupportedComponent } from './unauthorized/country-not-supported/country-not-supported';
import { InvalidBrowserComponent } from './unauthorized/invalid-browser/invalid-browser.component';
import { PrivacyPolicyCurrentComponent } from './legal/privacy-policy/privacy-policy-current/privacy-policy-current.component';
import { PrivacyPolicyV20210223Component } from './legal/privacy-policy/privacy-policy-v20210223/privacy-policy-v20210223.component';
import { CookiePolicyCurrentComponent } from './legal/cookie-policy/cookie-policy-current/cookie-policy-current.component';
import { CookiePolicyV20190119Component } from './legal/cookie-policy/cookie-policy-v20190119/cookie-policy-v20190119.component';
import { BiometricPolicyCurrentComponent } from './legal/biometric-policy/biometric-policy-current/biometric-policy-current.component';
import { TermsOfServicePolicyCurrentComponent } from './legal/terms-of-service/terms-of-service-policy-current/terms-of-service-policy-current.component';
import { AccessibilityStatementPolicyCurrentComponent } from './legal/accessibility-statement/accessibility-statement-policy-current/accessibility-statement-policy-current.component';
import { NoticeOfFinancialIncentivePolicyCurrentComponent } from './legal/notice-of-financial-incentive-policy/notice-of-financial-incentive-policy-current/notice-of-financial-incentive-policy-current.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BiometricPolicyV20220912Component } from './legal/biometric-policy/biometric-policy-v20220912/biometric-policy-v20220912.component';
import { PrivacyPolicyV20220915Component } from './legal/privacy-policy/privacy-policy-v20220915/privacy-policy-v20220915.component';
import { CookiePolicyV20220915Component } from './legal/cookie-policy/cookie-policy-v20220915/cookie-policy-v20220915.component';
import { SetRewardGoalComponent } from './signup/set-reward-goal/set-reward-goal.component';
import { PrivacyPolicyV20230719Component } from './legal/privacy-policy/privacy-policy-v20230719/privacy-policy-v20230719.component';
import { BiometricPolicyV20230719Component } from './legal/biometric-policy/biometric-policy-v20230719/biometric-policy-v20230719.component';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule.forChild(AnonymousRoutes),
    SweetAlert2Module.forRoot(),
    TranslateModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  declarations: [
    LostPasswordComponent,
    SignoutComponent,
    ImpersonateComponent,
    AccountCreationEmailComponent,
    GettingToKnowYouComponent,
    SuccessSentComponent,
    SuccessChangedComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SMSAuthenticationComponent,
    SMSSuccessAnimationComponent,
    GenericSharingMissionComponent,
    AccessDeniedComponent,
    InvalidBrowserComponent,
    CountryNotSupportedComponent,
    BlockedIPComponent,
    HomepageComponent,
    GeneralHeaderComponent,
    GeneralFooterComponent,
    GeneralRewardsComponent,
    GeneralRewardsListComponent,
    RedirectComponent,
    ReferAFriendComponent,
    AutoLoginComponent,
    LoadingMobileComponent,
    AccountCreateEmailComponent,
    GetToKnowYouComponent,
    AboutYourSelfComponent,
    AlmostThereComponent,
    GetVerifiedComponent,
    SetRewardGoalComponent,
    LegalComponent,
    PrivacyPolicyCurrentComponent,
    PrivacyPolicyV20220915Component,
    PrivacyPolicyV20210223Component,
    PrivacyPolicyV20230719Component,
    CookiePolicyCurrentComponent,
    CookiePolicyV20190119Component,
    BiometricPolicyCurrentComponent,
    TermsOfServicePolicyCurrentComponent,
    AccessibilityStatementPolicyCurrentComponent,
    NoticeOfFinancialIncentivePolicyCurrentComponent,
    BiometricPolicyV20220912Component,
    BiometricPolicyV20230719Component,
    CookiePolicyV20220915Component
  ],
  providers: [
    SignupService,
    TranslatePipe,
    NgbActiveModal,
    { provide: NgbCalendar, useClass: NgbCalendarHebrew },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nHebrew }
  ],
  exports: [GeneralHeaderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AnonymousModule {}
