import { Injectable } from '@angular/core';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import {
  ChallengePeriod,
  ChallengeType,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { find, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ChallengeService {
  constructor(
    private suzySdkService: SuzySdkService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.enableMissionChallenges =
      this.launchDarklyService.getCWDT1182Flag() &&
      this.launchDarklyService.getCWDT1182SM1691Flag();
    this.enableStreakChallenges =
      this.launchDarklyService.getCWDT1847SM1879Flag();

    this.enableStreaks =
      this.launchDarklyService.getCWDT1796Flag() &&
      this.launchDarklyService.getCWDT1796SM1809Flag();
  }

  private availableChallenge: AvailableChallenge[] = [];
  private currentChallenges: ChallengeInfo[] = [];
  private currentStreak: ChallengeInfo;
  private milestoneShownKey = 'challenge-milestone-shown';
  private availableChallengeSubject$ = new BehaviorSubject<
    AvailableChallenge[]
  >(undefined);
  private inProgressChallengeSubject$ = new BehaviorSubject<ChallengeInfo[]>(
    undefined
  );
  private streakSubject$ = new BehaviorSubject<number>(undefined);
  private milestoneModalSubject$ = new Subject<ChallengeInfo[]>();
  private enableMissionChallenges = false;
  private enableStreakChallenges = false;
  private enableStreaks = false;
  availableChallenge$ = this.availableChallengeSubject$.asObservable();
  inProgressChallenge$ = this.inProgressChallengeSubject$.asObservable();
  streak$ = this.streakSubject$.asObservable();
  milestoneModal$ = this.milestoneModalSubject$.asObservable();
  availableMissions = 0;

  private processInProgress(
    challenges: InProgressChallenge[] | ChallengeResponse[],
    showMilestone: boolean
  ): void {
    if (challenges.length === 0) {
      return;
    }
    try {
      let milestones: ChallengeInfo[] = [];
      challenges.forEach(challenge => {
        let stage = ChallengeStage.inProgress;
        if (challenge.awarded_utc) {
          stage = ChallengeStage.awarded;
        } else if (challenge.streak_broken_utc) {
          stage = ChallengeStage.failed;
        } else if (challenge.completed_utc) {
          stage = ChallengeStage.completed;
        }
        if (stage !== ChallengeStage.failed) {
          const inProgress = 'track_challenge' in challenge;

          const newChallenge: ChallengeInfo = {
            points: inProgress
              ? challenge.track_challenge?.award_points
              : undefined,
            required: this.missionsRequired(challenge),
            progress: this.missionsProgress(challenge),
            end_utc: new Date(challenge.expires_utc),
            updated_utc: challenge.updated_utc,
            track_challenge_id: challenge.track_challenge_id,
            challengeStage: stage,
            user_track_challenge_id: challenge.user_track_challenge_id,
            challenge_period: inProgress
              ? challenge.track_challenge?.challenge_period
              : undefined,
            challenge_type: inProgress
              ? challenge.track_challenge?.challenge_type
              : undefined,
            challenge_duration: inProgress
              ? challenge.track_challenge?.challenge_duration
              : undefined
          };
          this.currentChallenges = this.currentChallenges.filter(
            challenge =>
              challenge.user_track_challenge_id !==
              newChallenge.user_track_challenge_id
          );
          this.currentChallenges.push(newChallenge);

          milestones.push(newChallenge);
        }
      });

      if (milestones.length) {
        this.checkMilestone(milestones, showMilestone);
      }
    } catch (err) {
      console.log(err);
    }
    this.inProgressChallengeSubject$.next(this.currentChallenges);
  }

  private missionsRequired(
    challenge: InProgressChallenge | ChallengeResponse
  ): number {
    const inProgress = 'track_challenge' in challenge;
    if (
      inProgress &&
      'track_challenge_config' in challenge.track_challenge &&
      challenge.track_challenge.challenge_type === ChallengeType.mission
    ) {
      return challenge.track_challenge.track_challenge_config.mission
        ?.required_mission_count;
    } else if (
      inProgress &&
      challenge.track_challenge.challenge_type === ChallengeType.streak
    ) {
      return challenge.track_challenge?.challenge_duration;
    } else {
      return undefined;
    }
  }

  private missionsProgress(
    challenge: InProgressChallenge | ChallengeResponse
  ): number {
    const inProgress = 'track_challenge' in challenge;
    if ('progress' in challenge) {
      return challenge.progress.mission.mission_count <
        challenge.track_challenge.track_challenge_config.mission
          .required_mission_count
        ? challenge.progress.mission.mission_count
        : challenge.track_challenge.track_challenge_config.mission
            .required_mission_count;
    } else if (
      inProgress &&
      challenge.track_challenge?.challenge_type === ChallengeType.mission &&
      (challenge.awarded_utc || challenge.completed_utc)
    ) {
      return challenge.track_challenge.track_challenge_config.mission
        .required_mission_count;
    } else if (
      inProgress &&
      challenge.track_challenge?.challenge_type === ChallengeType.streak
    ) {
      return challenge.streak_count;
    } else {
      return undefined;
    }
  }

  private processStreak(challenge?: InProgressChallenge): void {
    if (challenge) {
      this.currentStreak = {
        points: undefined,
        required: undefined,
        progress: undefined,
        end_utc: new Date(challenge.expires_utc),
        updated_utc: challenge.updated_utc,
        track_challenge_id: challenge.track_challenge_id,
        challengeStage: undefined,
        user_track_challenge_id: challenge.user_track_challenge_id,
        challenge_period: challenge.track_challenge.challenge_period,
        challenge_type: challenge.track_challenge.challenge_type
      };
      this.streakSubject$.next(challenge.streak_count);
    } else {
      // user has never had a streak
      this.streakSubject$.next(0);
    }
  }

  private checkMilestone(
    challenges: ChallengeInfo[],
    showMilestone: boolean
  ): void {
    let progressMilestones: { challenge: ChallengeInfo; milestone: string }[] =
      [];
    let completeMilestones: { challenge: ChallengeInfo; milestone: string }[] =
      [];
    challenges.forEach(challenge => {
      if (
        challenge &&
        challenge.challengeStage === ChallengeStage.inProgress &&
        challenge.required !== undefined
      ) {
        const milestoneOne = Math.ceil(challenge.required / 3);
        const milestoneTwo = Math.ceil((2 * challenge.required) / 3);

        if (showMilestone && challenge.progress === milestoneOne) {
          progressMilestones.push({
            challenge,
            milestone: '1'
          });
        } else if (showMilestone && challenge.progress === milestoneTwo) {
          progressMilestones.push({
            challenge,
            milestone: '2'
          });
        } else if (challenge.progress === challenge.required) {
          if (challenge.challenge_type === ChallengeType.mission) {
            this.completeChallenge(
              challenge.user_track_challenge_id
            ).subscribe();
            if (showMilestone) {
              challenge.challengeStage = ChallengeStage.completed;
              completeMilestones.push({
                challenge,
                milestone: '3'
              });
            }
          }
        }
      } else if (
        showMilestone &&
        challenge &&
        (challenge.challengeStage === ChallengeStage.completed ||
          challenge.challengeStage === ChallengeStage.awarded)
      ) {
        completeMilestones.push({
          challenge,
          milestone: '3'
        });
      }
    });

    if (progressMilestones.length) {
      this.checkShowMilestone(progressMilestones);
    }
    if (completeMilestones.length) {
      this.checkShowMilestone(completeMilestones);
    }
  }

  private extendStreakAfterLoad(): Observable<boolean> {
    if (this.currentStreak && this.isTodayEst(this.currentStreak.updated_utc)) {
      return of(false);
    }
    const streak =
      this.suzySdkService.ProtocolChallenge.processStreakChallenges();

    return streak.pipe(
      map((data: any) => {
        if (data && data.success) {
          const streakChallenge: InProgressChallenge[] =
            data.item.in_progress.filter(challenge => {
              return (
                challenge.track_challenge.challenge_type ===
                ChallengeType.streak_infinite
              );
            });

          if (streakChallenge.length > 0) {
            if (!this.isTodayEst(streakChallenge[0].updated_utc)) {
              return false;
            }
            this.processStreak(streakChallenge[0]);

            return true;
          }
        } else {
          return false;
        }
      })
    );
  }

  private getMidnightEst(): Date {
    let now = new Date();
    now.setHours(24 + this.getEstOffsetHours(), 0, 0, 0);

    return now;
  }

  private getEstOffsetHours(): number {
    const localTime: Date = new Date();
    const estTime: Date = this.getDateEst();

    return Math.round(
      (localTime.getTime() - estTime.getTime()) / (60 * 60 * 1000)
    );
  }

  private getDateUtcInt(): number {
    const now = new Date();

    return this.getDateInt(now);
  }

  private getDateEstInt(): number {
    return this.getDateInt(this.getDateEst());
  }

  private getDateEst(): Date {
    let currentEst = this.dateToEst(new Date());

    return currentEst;
  }

  private getDateInt(d: Date): number {
    return parseInt(
      d.getUTCFullYear().toString() +
        d.getUTCMonth().toString().padStart(2, '0') +
        d.getUTCDate().toString().padStart(2, '0'),
      10
    );
  }
  private dateToEst(dateUtc: Date): Date {
    return new Date(
      dateUtc.toLocaleString('en-US', { timeZone: 'America/New_York' })
    );
  }

  private isTodayEst(dateUtc: Date): boolean {
    if (dateUtc === undefined) {
      return false;
    }

    const dateEst = this.getDateInt(this.dateToEst(dateUtc));

    return this.isTodayEstNum(dateEst);
  }

  private isTodayEstNum(dateEst: number): boolean {
    return dateEst === this.getDateEstInt();
  }

  private isYesterdayEst(dateUtc: Date): boolean {
    if (dateUtc === undefined) {
      return false;
    }

    const dateEst = this.getDateInt(this.dateToEst(dateUtc));

    return this.isYesterdayEstNum(dateEst);
  }

  private isYesterdayEstNum(dateEst: number): boolean {
    return dateEst === this.getYesterdayEstInt();
  }

  private getYesterdayEstInt(): number {
    return this.getDateInt(this.getYesterdayEst());
  }

  private getYesterdayEst(): Date {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    let currentEst = this.dateToEst(d);

    return currentEst;
  }

  private checkShowMilestone(
    challenges: { challenge: ChallengeInfo; milestone: string }[]
  ): void {
    let key = this.milestoneShownKey;
    let progressMilestones: MilestoneProgress[] = [];
    const progress = localStorage.getItem(key);
    let showMilestones: ChallengeInfo[] = [];

    if (progress && progress.startsWith('[')) {
      progressMilestones = JSON.parse(progress);
    }

    challenges.forEach(challenge => {
      if (
        !progressMilestones.find(
          m =>
            m.user_track_challenge_id ===
              challenge.challenge.user_track_challenge_id &&
            m.milestone === challenge.milestone
        )
      ) {
        showMilestones.push(challenge.challenge);
        progressMilestones.push({
          milestone: challenge.milestone,
          user_track_challenge_id: challenge.challenge.user_track_challenge_id,
          expires_utc: ''
        });
      }
    });

    if (showMilestones.length) {
      this.milestoneModalSubject$.next(showMilestones);
      localStorage.setItem(key, JSON.stringify(progressMilestones));
    }
  }

  clearMilestoneProgress(userTrackChallengeId: string): void {
    let key = this.milestoneShownKey;
    const progress = localStorage.getItem(key);

    // starsWith to check for existing previous format
    if (progress && progress.startsWith('[')) {
      let progressMilestones: MilestoneProgress[] = [];
      progressMilestones = JSON.parse(progress);
      if (
        progressMilestones.find(
          m => m.user_track_challenge_id === userTrackChallengeId
        )
      ) {
        progressMilestones = progressMilestones.filter(
          m => m.user_track_challenge_id !== userTrackChallengeId
        );
        localStorage.setItem(key, JSON.stringify(progressMilestones));
      }
    }
  }

  getChallenges(showAvailable = true): void {
    this.suzySdkService.ProtocolChallenge.getAvailableChallenges().subscribe(
      data => {
        if (data && data.success) {
          const challenges: Challenges = data;

          if (this.enableStreaks) {
            const streakInfiniteChallenge = challenges.item.in_progress.filter(
              challenge => {
                return (
                  challenge.track_challenge.challenge_type ===
                  ChallengeType.streak_infinite
                );
              }
            );

            if (streakInfiniteChallenge.length > 0) {
              this.processStreak(streakInfiniteChallenge[0]);
            } else {
              this.processStreak();
            }
          }

          let in_progress = challenges.item.in_progress.filter(challenge => {
            return (
              (challenge.track_challenge.challenge_type ===
                ChallengeType.mission &&
                this.enableMissionChallenges) ||
              (challenge.track_challenge.challenge_type ===
                ChallengeType.streak &&
                this.enableStreakChallenges)
            );
          });

          if (in_progress.length > 0) {
            in_progress.sort(function (
              a: InProgressChallenge,
              b: InProgressChallenge
            ): number {
              return (
                (a?.track_challenge?.track_challenge_config?.priority ?? 0) -
                (b?.track_challenge?.track_challenge_config?.priority ?? 0)
              );
            });
            this.processInProgress(in_progress, false);
          } else if (this.inProgressChallengeSubject$ !== undefined) {
            this.inProgressChallengeSubject$.next(undefined);
          }

          if (challenges?.item?.completed?.length > 0) {
            const completed = challenges.item.completed.filter(challenge => {
              return !challenge.awarded_utc;
            });
            const awarded = challenges.item.completed.filter(challenge => {
              return this.isTodayEst(new Date(challenge.awarded_utc));
            });
            if (awarded.length > 0) {
              this.processInProgress(awarded, false);
            }
          }
          if (showAvailable) {
            this.availableChallenge = [];
            if (challenges?.item?.available?.length > 0) {
              let eligibleChallenges = challenges.item.available.filter(
                challenge => {
                  return (
                    challenge.enabled &&
                    ((challenge.challenge_type === ChallengeType.mission &&
                      this.enableMissionChallenges &&
                      (challenge.track_challenge_config.mission
                        .mission_eligibility_threshold === undefined ||
                        this.availableMissions >=
                          challenge.track_challenge_config.mission
                            .mission_eligibility_threshold)) ||
                      (challenge.challenge_type === ChallengeType.streak &&
                        this.enableStreakChallenges))
                  );
                }
              );

              if (eligibleChallenges.length > 0) {
                eligibleChallenges.sort(function (
                  a: AvailableChallenge,
                  b: AvailableChallenge
                ): number {
                  return (
                    (a?.track_challenge_config?.priority ?? 0) -
                    (b?.track_challenge_config?.priority ?? 0)
                  );
                });
                let addedChallenges = false;
                eligibleChallenges.forEach(challenge => {
                  if (!this.availableChallenge.includes(challenge)) {
                    this.availableChallenge.push(challenge);
                    addedChallenges = true;
                  }
                });
                if (addedChallenges) {
                  this.availableChallengeSubject$.next(this.availableChallenge);
                }
              } else {
                if (this.availableChallengeSubject$ !== undefined) {
                  this.availableChallengeSubject$.next(undefined);
                }
              }
            } else {
              if (this.availableChallengeSubject$ !== undefined) {
                this.availableChallengeSubject$.next(undefined);
              }
            }
          }
        }
      }
    );
  }

  startChallenge(track_challenge_ids: string[]): void {
    let challengesToStart = track_challenge_ids;
    track_challenge_ids.forEach(track_challenge_id => {
      const challenge =
        this.suzySdkService.ProtocolChallenge.startChallenge(
          track_challenge_id
        );
      challenge.subscribe(data => {
        if (data && data.success) {
          const challenge: ChallengeWrapper = data;
          // in case challenge has previous progress
          this.clearMilestoneProgress(challenge.item.user_track_challenge_id);
          this.getStatus(challenge.item.user_track_challenge_id);
          const challengeId = challenge.item.track_challenge_id;
          challengesToStart = challengesToStart.filter(id => {
            return id !== challengeId;
          });
          if (challengesToStart.length === 0) {
            this.availableChallengeSubject$.next(undefined);
          }
        }
      });
    });
  }

  getStreakStatus(): void {
    if (
      this.currentStreak &&
      !this.isTodayEst(new Date(this.currentStreak.updated_utc))
    ) {
      this.getStatus(this.currentStreak.user_track_challenge_id);
    } else if (!this.currentStreak && this.enableStreaks) {
      this.getChallenges();
    }
  }

  getChallengeStatus(): void {
    this.getStatus();
  }

  private getStatus(user_track_challenge_id?: string): void {
    let challenges: string[];
    if (user_track_challenge_id) {
      challenges = [user_track_challenge_id];
    } else {
      challenges = this.currentChallenges.map(c => c.user_track_challenge_id);
    }
    let toProcess: InProgressChallenge[] = [];
    challenges.forEach(id => {
      const challenge =
        this.suzySdkService.ProtocolChallenge.getUserTrackChallenge(id);
      challenge.subscribe(data => {
        if (data && data.success) {
          const in_progress: InProgressChallenge = data.item;
          if (
            in_progress.track_challenge.challenge_type ===
              ChallengeType.mission ||
            in_progress.track_challenge.challenge_type === ChallengeType.streak
          ) {
            toProcess.push(in_progress);
            if (toProcess.length === challenges.length) {
              this.processInProgress(toProcess, true);
            }
          } else if (
            in_progress.track_challenge.challenge_type ===
            ChallengeType.streak_infinite
          ) {
            this.processStreak(in_progress);
          }
        }
      });
    });
  }

  completeChallenge(
    user_track_challenge_id: string
  ): Observable<ChallengeWrapper> {
    const challenge = this.suzySdkService.ProtocolChallenge.completeChallenge(
      user_track_challenge_id
    );

    challenge.subscribe((data: ChallengeWrapper) => {
      if (data && data.success && data.item?.completed_utc) {
        this.currentChallenges.forEach(challenge => {
          if (challenge.user_track_challenge_id === user_track_challenge_id) {
            challenge.challengeStage = ChallengeStage.completed;
          }
        });
        this.inProgressChallengeSubject$.next(this.currentChallenges);
      }
    });

    return challenge;
  }

  extendStreakWithoutMission(): Observable<boolean> {
    if (this.enableStreaks) {
      return this.extendStreakAfterLoad();
    } else {
      return of(false);
    }
  }

  getTimeRemaining(challenge: ChallengeInfo): string {
    let end_utc: Date;
    if (
      challenge?.challenge_duration === 1 ||
      challenge.challenge_type === ChallengeType.streak
    ) {
      end_utc = this.getMidnightEst();
    } else {
      end_utc = challenge.end_utc;
    }
    const ms = end_utc.getTime() - Date.now();
    const s = Math.floor(ms / 1000);
    const m = Math.floor(s / 60);
    const h = Math.floor(m / 60);
    const days = Math.floor(h / 24);
    const hours = h % 24;
    const mins = m % 60;

    let remaining = '';
    if (days) {
      remaining += days.toString() + 'd ';
    }
    if (remaining || hours) {
      remaining += hours.toString() + 'h ';
    }
    if (remaining || mins) {
      remaining += mins.toString() + 'm';
    }

    return remaining;
  }
}

export class MilestoneProgress {
  milestone: string;
  user_track_challenge_id: string;
  expires_utc: string;
}

export class Challenges {
  item: ChallengeItem;
  success: boolean;
}

export class ChallengeStatus {
  item: InProgressChallenge;
  success: boolean;
}

export class ChallengeWrapper {
  item: ChallengeResponse;
  success: boolean;
}

export class ChallengeItem {
  in_progress: InProgressChallenge[];
  completed: ChallengeResponse[];
  available: AvailableChallenge[];
}
export class ChallengeMission {
  required_mission_count: number;
  mission_eligibility_threshold: number;
}
export class TrackChallengeConfig {
  mission?: ChallengeMission;
  priority: number;
}

export class ProgressMission {
  mission_count: number;
}
export class Progress {
  mission: ProgressMission;
}

export class ChallengeInfo {
  points: number;
  required: number;
  progress: number;
  end_utc: Date;
  updated_utc: Date;
  track_challenge_id: string;
  challengeStage: ChallengeStage;
  user_track_challenge_id?: string;
  challenge_period?: ChallengePeriod;
  challenge_type?: ChallengeType;
  challenge_duration?: number;
}

export class ChallengeResponse {
  user_track_challenge_key: string;
  user_track_challenge_id: string;
  user_id: string;
  user_track_id: string;
  track_challenge_id: string;
  start_utc: string;
  expires_utc?: string;
  created_utc: string;
  sync_success_utc: string;
  awarded_utc?: string;
  completed_utc?: Date;
}

export class Challenge extends ChallengeResponse {
  updated_utc: Date;
  streak_broken_utc?: Date;
  streak_count?: number;
  previous_streak_count?: number;
  highest_streak_count?: number;
  track_challenge: TrackChallenge;
}

export class AvailableChallenge {
  track_challenge_id: string;
  track_id: string;
  challenge_name: string;
  description: string;
  challenge_period: ChallengePeriod;
  challenge_type: ChallengeType;
  challenge_duration?: number;
  enabled: boolean;
  start_utc: string;
  end_utc: string;
  award_points: number;
  track_challenge_config?: TrackChallengeConfig;
}

export class InProgressChallenge extends Challenge {
  progress?: Progress;
}

export class TrackChallenge extends AvailableChallenge {
  sync_success_utc: Date;
}

export enum ChallengeStage {
  available = 0,
  inProgress = 1,
  completed = 2,
  awarded = 3,
  failed = 4
}
