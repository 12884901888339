<h1>Biometric Data Privacy Notice</h1>
<p>Updated: August 28, 2024</p>
<p>
  Suzy, Inc., the owner of the Crowdtap platform, and its subsidiaries and
  affiliates (collectively, “Suzy”, “we,” “our” or “us”) cares about your
  privacy and wants to protect you and us against security incidents. Suzy and
  our contractors may use advanced technology to verify your identity and
  safeguard your personal information as part of our security and fraud
  prevention efforts, as well as to provide the products and services you
  requested.
</p>
<p>
  This Biometric Data Privacy Notice (“Notice”) describes our collection and use
  of biometric data. It should be read in conjunction with our
  <a routerLink="/privacy-policy"> Privacy Policy. </a> for Crowdtap Members or
  our Privacy Notice for External Audiences depending on who you are and how you
  interact with us.
</p>
<h2>Table of Contents</h2>
<div class="toc">
  <ul>
    <li>
      <a routerLink="./" fragment="what-is-biometric-data">
        What is Biometric Data?</a
      >
    </li>
    <li>
      <a
        routerLink="./"
        fragment="how-we-collect-use-store-and-share-your-biometric-data"
      >
        How We Collect, Use, Store, and Share Your Biometric Data
      </a>
      <ul>
        <li>
          <a
            routerLink="./"
            fragment="verifying-your-crowdtap-account-using-selfies"
          >
            1. Verifying your Crowdtap Account Using Selfies
          </a>
        </li>
        <li>
          <a
            routerLink="./"
            fragment="participating-in-live-interviews-or-focus-groups"
          >
            2. Participating in Live Interviews or Focus Groups
          </a>
        </li>
        <li>
          <a
            routerLink="./"
            fragment="submitting-video-recordings-in-response-to-crowdtap-actions"
          >
            3. Submitting Video Recordings in Response to Crowdtap Actions
          </a>
        </li>
      </ul>
    </li>
    <li>
      <a routerLink="./" fragment="your-privacy-choices"
        >Your Privacy Choices</a
      >
    </li>
    <li>
      <a routerLink="./" fragment="notice-to-illinois-residents"
        >Notice to Illinois Residents</a
      >
    </li>
    <li><a routerLink="./" fragment="contact-us">Contact Us</a></li>
    <li>
      <a routerLink="./" fragment="changes-to-notice">Changes to Notice</a>
    </li>
  </ul>
</div>
<h2 id="what-is-biometric-data">What is Biometric Data?</h2>
<p>
  When we refer to “biometric data,” we mean information about your physical or
  biological characteristics (some states call these “biometric identifiers”)
  that identify you. Information like eye, hand, or face scans, fingerprints,
  and voiceprints may be considered biometric data if they are being used to
  identify you.
</p>
<p>
  The definitions of biometric data vary under different countries' and/or
  states’ laws. For example, some states’ biometric laws don’t apply to video
  and audio recordings, while other states might take the opposite approach. As
  another example, photos of you may not be considered biometric data in some
  countries or states, but biometric data could be created from processing the
  photo to identify you.
</p>
<p>
  Ultimately, Suzy treats photographs and videos of you with special care, even
  if they’re not technically “biometric data” under a particular jurisdiction’s
  laws. Throughout the rest of this Notice, we use the term “Biometric Data”
  loosely to include photographs and videos of you, even if they’re not
  technically so.
</p>
<h2 id="how-we-collect-use-store-and-share-your-biometric-data">
  How We Collect, Use, Store, and Share Your Biometric Data
</h2>
<p>
  The way we collect, use, store, and share your Biometric Data depends on which
  features you choose to participate in, categorized below:
</p>
<h3 id="verifying-your-crowdtap-account-using-selfies">
  <strong>1. Verifying your Crowdtap Account Using Selfies</strong>
</h3>

<div class="bordered-section" [class.expanded-panel-active]="verification">
  <div class="header-container">
    <img
      [class.rotate-arrow]="verification"
      src="/assets/icons/svg/ic-arrow-down-grey.svg"
      alt="Arrow icon"
    />
    <span (click)="toggle('verification')" class="expanded-panel-title"
      >Read Details</span
    >
  </div>
  <ng-container *ngIf="verification">
    <div class="expanded-panel">
      <p>
        If you choose to use our Crowdtap mobile application, you will be asked
        to
        <a
          href="https://support.crowdtap.com/hc/en-us/articles/360058122672-Verifying-on-mobile-with-photo-verify"
          >verify your account</a
        >
        shortly after joining. You have the option to verify your account using
        ReCAPTCHA or by taking two selfies using your mobile device (“<a
          href="https://support.crowdtap.com/hc/en-us/articles/360058122672-Verifying-on-mobile-with-photo-verify"
          >Photo Verify</a
        >”). If you choose Photo Verify, your Biometric Data will be collected,
        processed, and shared with third party service providers.
      </p>
      <p>
        If you choose Photo Verify, the image(s) you submit from your mobile
        device will be collected, processed, and shared with our third party
        partner, Microsoft, via
        <a
          href="https://azure.microsoft.com/en-in/services/cognitive-services/computer-vision/#overview"
          >Azure Computer Vision</a
        >. We will use the images to ensure that you are real and that no one
        else is using your identity to claim rewards. We do this so we can
        provide the Crowdtap products and services you requested.
      </p>

      <p>
        We will protect your images using industry best practices with the
        highest degree of sensitivity classification. The images you submit are
        not stored on our servers.
      </p>

      <p>
        Microsoft automatically deletes your images and videos after processing
        and doesn’t learn from your data to enhance its underlying models.
        Microsoft is a U.S. company. You can learn more about their privacy
        practices
        <a
          href="https://azure.microsoft.com/en-in/support/legal/cognitive-services-compliance-and-privacy/"
          >here</a
        >.
      </p>
      <p>
        We also encourage you to review the privacy notices that apply to your
        particular mobile device.
      </p>
    </div>
  </ng-container>
</div>

<div class="expanded-details" *ngIf="showPhotoVerify">
  <p>
    If you choose Photo Verify, the image(s) you submit from your mobile device
    will be collected, processed, and shared with our third party partner,
    Microsoft, via
    <a
      href="https://azure.microsoft.com/en-in/services/cognitive-services/computer-vision/#overview"
    >
    </a>
    <a
      href="https://azure.microsoft.com/en-in/services/cognitive-services/computer-vision/#overview"
    >
      Azure Computer Vision
    </a>
    . We will use the images to ensure that you are real and that no one else is
    using your identity to claim rewards. We do this so we can provide the
    Crowdtap products and services you requested.
  </p>
  <p>
    We will protect your images using industry best practices with the highest
    degree of sensitivity classification. The images you submit are not stored
    on our servers.
  </p>
  <p>
    Microsoft automatically deletes your images and videos after processing and
    doesn't learn from your data to enhance its underlying models. Microsoft is
    a U.S. company. You can learn more about their privacy practices
    <a
      href="https://azure.microsoft.com/en-in/support/legal/cognitive-services-compliance-and-privacy/"
    >
    </a>
    <a
      href="https://azure.microsoft.com/en-in/support/legal/cognitive-services-compliance-and-privacy/"
    >
      here
    </a>
    .
  </p>
  <p>
    We also encourage you to review the privacy notices that apply to your
    particular mobile device.
  </p>
</div>

<h3 id="participating-in-live-interviews-or-focus-groups">
  <strong>2. Participating in Live Interviews or Focus Groups</strong>
</h3>
<div class="bordered-section" [class.expanded-panel-active]="participant">
  <div class="header-container">
    <img
      [class.rotate-arrow]="participant"
      src="/assets/icons/svg/ic-arrow-down-grey.svg"
      alt="Arrow icon"
    />
    <span (click)="toggle('participant')" class="expanded-panel-title"
      >Read Details</span
    >
  </div>
  <ng-container *ngIf="participant">
    <div class="expanded-panel">
      <p>
        Two products we offer our Clients are Live In-Depth Interviews (“IDIs”)
        and Live Focus Groups.
      </p>
      <ul>
        <li>IDIs are virtual 1-on-1 interviews</li>
        <li>
          Focus Groups are virtual interviews consisting of you and 3-4 other
          participants
        </li>
      </ul>
      <p>
        From time to time, you may be
        <a
          href="https://support.crowdtap.com/hc/en-us/articles/360050008911-Crowdtap-interviews"
          >invited to participate</a
        >
        in an IDI or Focus Group. If you choose to participate in either, your
        Biometric Data will be collected, processed, and shared with our Brand
        Clients.
      </p>

      <p>
        <strong>What is the purpose of the IDIs and Focus Groups?</strong>
      </p>
      <ul>
        <li>
          Sometimes surveys aren't enough! While regular survey questions give
          our Clients an idea of what you think, feel and purchase, live
          interviews can help them learn <em>why.</em>
        </li>
        <li>
          The benefit to you, if you choose to participate, are enhanced
          rewards/points.
        </li>
      </ul>
      <p><strong>What information is involved?</strong></p>
      <ul>
        <li>
          During the live interaction, your first name and last initial will
          appear beside you (as you appear on camera). We will be able to see
          this information, as will the following individuals:
          <ul class="inner-list">
            <li>
              A moderator / interviewer. This person is from Suzy, the Client,
              or one of Suzy’s subcontractors.
            </li>
            <li>
              A notetaker. This person is from Suzy, the Client, or one of
              Suzy’s subcontractors.
            </li>
            <li>
              Other people from Suzy or our Client may also attend, but you
              won’t see them. They’ll be in the virtual “back room” – meaning,
              we can see them and they can see you, but you can’t see them. For
              example, someone from Suzy may attend to provide administrative
              support.
            </li>
            <li>
              Other Crowdtap members or participants, if you are participating
              in a Focus Group.
            </li>
          </ul>
        </li>
        <li>
          We will record the <strong>entire</strong> live interaction, including
          your face, your voice, and your name--which are uniquely attributable
          to you and can be used to identify you.
        </li>
        <li>
          The live interaction and recording will also include your responses to
          questions, which may include identifying or sensitive information.
          However, you are not required to share any information that makes you
          uncomfortable. If there is a question you’d prefer not to answer,
          kindly let the moderator know.
        </li>
        <li>
          To prevent the inadvertent capturing of any other personal
          information, you must:
          <ul class="inner-list">
            <li>
              make sure your background is free from personal and/or sensitive
              information (things like school diplomas, financial statements,
              family photos, calendar details, street signs in background, etc).
            </li>
            <li>
              make sure that the clothing you wear is free from information that
              could be considered personal information (things like a sweater
              reflecting your school’s name or mascot, a hat reflecting your
              local sports team, etc).
            </li>
          </ul>
        </li>
        <li>
          We will moderate IDIs and Focus Groups using:
          <ul class="inner-list">
            <li>human beings who watch and listen to the sessions; and/or</li>
            <li>
              advanced technology, including artificial intelligence software,
              to process audio and video
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <strong>Why do you collect this information?</strong>
      </p>
      <ul>
        <li>
          We collect your first name and last initial so the moderator can
          naturally converse with you during the interaction (for example, “Hi,
          John” instead of “Hi, User ID 12345”).
        </li>
        <li>
          We capture your image and audio to effectuate qualitative market
          research services.
        </li>
        <li>
          We utilize advanced technology, including artificial intelligence
          software, to moderate the sessions and recordings to ensure quality
          assurance;, to prepare transcripts; to provide, improve, support, and
          operate market research services for our Clients; and for security
          purposes.
        </li>
      </ul>
      <p>
        <strong>What do you do with the information?</strong>
      </p>
      <ul>
        <li>
          Suzy will use the information for its brand research and insights,
          advertising, and/or promotions.
        </li>
        <li>
          Suzy will share both the live interaction(s) and the recording(s) with
          the Client who purchased license(s) to Suzy’s insights platform and
          initiated the IDI or Focus Group. That Client will use the live
          interaction(s) and recording(s) for their brand research and insights,
          advertising, and/or promotions.
        </li>

        <li>
          Suzy will also share your data with third-party and internal
          artificial intelligence features in order to provide enhanced market
          research services to our Clients.
        </li>
        <li>
          The licenses that Clients purchase from Suzy include access to the
          recording(s), which means that they could watch and listen to your
          recording, read a transcript of audio, download a full copy of the
          recording, download or create “clips” or “reels” using the recording,
          and otherwise use the recording for their brand research and insights,
          advertising, and/or promotions.
        </li>
        <li>
          You may never know which company (i.e., which of Suzy’s Clients)
          initiated the IDI or Focus Group and will be receiving and using the
          recording. It could be a sports company, it could be an apparel
          company, or it could be a company whose values do not align with
          yours. You are always welcome to
          <a
            href="https://support.crowdtap.com/hc/en-us/articles/4409666046356-Crowdtap-support"
            >ask us</a
          >
          which Client it is (and you might be able to infer based on the
          questions asked). However, please know that we are contractually
          prohibited from disclosing their identity to you in most situations.
        </li>
        <li>
          To see examples of some of the information the Clients can see from
          their perspective, visit:
          <ul class="inner-list">
            <li>
              the
              <a href="https://suzy.com/suzy-live-qualitative-research"
                >Suzy Live product offering page</a
              >
              or
            </li>
            <li>
              the
              <a
                href="https://support.suzy.com/hc/en-us/articles/360051204332-How-can-I-view-the-videos-transcripts-and-other-documents-from-my-Suzy-Live-interview-"
                >Suzy Live support page</a
              >.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <strong
          >How do you store, transmit, and protect the information?</strong
        >
      </p>
      <ul>
        <li>
          We store, transmit, and protect your Biometric Data using industry
          best practices with the highest degree of sensitivity classification.
        </li>
      </ul>

      <p>
        <strong>How long do you keep the information?</strong>
      </p>
      <ul>
        <li>
          We retain the information for as long as your account is active or for
          no longer than necessary to provide you or our Clients the services
          requested; to comply with our legal obligations; or to protect against
          or prevent actual or potential fraud, criminal activity, claims,
          security threats, or liability.
        </li>
      </ul>

      <p>
        <strong>What other parties are involved?</strong>
      </p>
      <ul>
        <li>
          The live interactions are conducted via our Suzy / Crowdtap platform.
          In rare instances, the live interactions may occur via Zoom or Google
          Meet. Zoom Video Communications, Inc. is a U.S. company. You can
          review Zoom's privacy practices
          <a href="https://explore.zoom.us/en/privacy/">here</a>. Google is a
          U.S. company. You can review Google's privacy practices
          <a href="https://support.google.com/meet/answer/9852160?hl=en">here</a
          >.
        </li>
        <li>
          To communicate with you regarding scheduling and troubleshooting, we
          utilize
          <a
            href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0059932#:~:text=Zoom%20Phone%20SMS%20allows%20you,sending%20SMS%20to%20certain%20locations."
            >Zoom’s SMS feature</a
          >. This means that your phone number is shared with / used in Zoom’s
          SMS functionality. Zoom Video Communications, Inc. is a U.S. company.
          You can review Zoom's privacy practices
          <a href="https://explore.zoom.us/en/privacy/">here</a>. No mobile
          information will be shared with third parties/affiliates for
          marketing/promotional purposes. All other categories exclude text
          messaging originator opt-in data and consent; this information will
          not be shared with any third parties.
        </li>
        <li>
          The software we use to process audio into written transcripts is
          Microsoft’s Azure Cognitive Services. The software we use to provide
          artificial intelligence summaries to our Clients is Azure OpenAI.
          Microsoft is a U.S. company. You can review Microsoft's privacy
          practices
          <a
            href="https://azure.microsoft.com/en-in/support/legal/cognitive-services-compliance-and-privacy/"
            >here</a
          >.
        </li>
        <li>
          The third party we use to process video is Twilio, Inc. Twilio is a
          U.S. company. You can review Twilio’s privacy practices
          <a href="https://www.twilio.com/legal/privacy">here</a>.
        </li>
        <li>
          Sometimes, we utilize contractors and other service providers to
          assist in our provision of IDIs, Focus Groups, and related services.
          These companies or individuals are authorized to use your Biometric
          Data only as necessary to provide their services to us. One example is
          that we sometimes use third party providers to help us make “clips” or
          “reels” of videos, which Clients could use for their brand research
          and insights, advertising, and/or promotions. You can review our full
          list of subprocessors
          <a href="https://suzy.com/subprocessor-list">here</a>.
        </li>
        <li>
          You should also consult the privacy notices associated with the device
          you use to attend the live interaction. For example, if you attend via
          a Mac computer, you should consult Apple’s applicable privacy notice.
        </li>
      </ul>
    </div>
  </ng-container>
</div>

<h3 id="submitting-video-recordings-in-response-to-crowdtap-actions">
  <strong> 3. Submitting Video Recordings in Response to an Action </strong>
</h3>
<div class="bordered-section" [class.expanded-panel-active]="submitting">
  <div class="header-container">
    <img
      [class.rotate-arrow]="submitting"
      src="/assets/icons/svg/ic-arrow-down-grey.svg"
      alt="Arrow icon"
    />
    <span (click)="toggle('submitting')" class="expanded-panel-title"
      >Read Details</span
    >
  </div>
  <ng-container *ngIf="submitting">
    <div class="expanded-panel">
      <p>
        You may receive survey questions that are open-ended questions. (For
        more details on what is an open-ended question, see Section 7 of our
        <a
          href="https://crowdtap.atlassian.net/wiki/spaces/Compliance/pages/2479915165/Crowdtap+Privacy+Policy#(e)-Providing-open-ended-text-responses"
        >
          Crowdtap Privacy Policy
        </a>
        or Section 7 of our Privacy Notice to External Audiences).
      </p>

      <p>
        Sometimes, you will be asked to respond to open-ended questions with a
        video recording. We call this “Video Open End” or “VOE” for short. If
        you choose to participate in VOE, your Biometric Data will be collected,
        processed, and shared with our Clients.
      </p>

      <p>
        <strong>What is the purpose of VOE?</strong>
      </p>
      <ul>
        <li>
          Sometimes static answers aren't enough! Open-ended questions give our
          Clients an idea of what you think, feel, and purchase, and
          <em>why.</em>
        </li>
        <li>
          The benefit to you, if you choose to participate, are enhanced
          rewards/points.
        </li>
      </ul>
      <p>
        <strong>What information is involved?</strong>
      </p>

      <ul>
        <li>
          The VOE recording would include things like your face and name that
          can be used to identify you. The recording will also include your
          responses to questions, which may include identifying or sensitive
          information. To prevent the inadvertent capturing of any other
          personal information, you must:
          <ul class="inner-list">
            <li>
              make sure your background is free from personal and/or sensitive
              information (things like school diplomas, financial statements,
              family photos, calendar details, street signs in the background,
              etc).
            </li>
            <li>
              make sure that the clothing you wear is free from information that
              could be considered personal information (things like a sweater
              reflecting your school’s name or mascot, a hat reflecting your
              local sports team, etc).
            </li>
          </ul>
        </li>
        <li>
          We will moderate VOE recordings using:
          <ul>
            <li>human beings who watch and listen to the recordings; and/or</li>
            <li>
              advanced technology, including artificial intelligence software,
              to process audio and video
            </li>
          </ul>
        </li>
      </ul>

      <p>
        <strong>Why do you collect this information?</strong>
      </p>
      <ul>
        <li>
          We collect your first name and last initial so the moderator can
          naturally converse with you during the interaction (for example, “Hi,
          John” instead of “Hi, User ID 12345”).
        </li>
        <li>
          We capture your image and audio to effectuate qualitative market
          research services.
        </li>
        <li>
          We utilize advanced technology, including artificial intelligence
          software, to moderate the sessions and recordings to ensure quality
          assurance; to prepare transcripts; to provide, improve, support, and
          operate market research services for our Clients; and for security
          purposes.
        </li>
      </ul>
      <p>
        <strong>What do you do with the information?</strong>
      </p>
      <ul>
        <li>
          Suzy will use the information for its brand research and insights,
          advertising, and/or promotions. For example, Suzy may share
          recording(s) with prospects or potential clients that Suzy wishes to
          engage.
        </li>
        <li>
          Suzy will share your recording(s) with the Client who purchased
          license(s) to Suzy’s insights platform and initiated the VOE. That
          Client will use your recording(s) for their brand research and
          insights, advertising, and/or promotions.
        </li>
        <li>
          Suzy will also share your data with third-party and internal
          artificial intelligence features in order to provide enhanced market
          research services to our Clients.
        </li>
        <li>
          The licenses that Clients purchase from Suzy include access to your
          recording(s), which means that they could watch and listen to your
          video, read a transcript of your audio, download a full copy of your
          video, download or create “clips” or “reels” using the recording, and
          otherwise use your recording for their brand research and insights,
          advertising, and/or promotions.
        </li>
        <li>
          You will not know which company (i.e., which of Suzy’s Clients)
          initiated the VOE and will be receiving and using your recording. It
          could be a sports company, it could be an apparel company, or it could
          be a company whose values do not align with yours. You are always
          welcome to
          <a
            href="https://support.crowdtap.com/hc/en-us/articles/4409666046356-Crowdtap-support"
            >ask us</a
          >
          which Client it is (and you might be able to infer based on the
          questions asked). However, please know that we are contractually
          prohibited from disclosing their identity to you in most situations.
        </li>
      </ul>
      <p>
        <strong
          >How do you store, transmit, and protect the information?</strong
        >
      </p>
      <ul>
        <li>
          We store, transmit, and protect your Biometric Data using industry
          best practices with the highest degree of sensitivity classification.
        </li>
      </ul>
      <p>
        <strong>How long do you keep the information?</strong>
      </p>
      <ul>
        <li>
          We retain the information for as long as your account is active or for
          no longer than necessary to provide you or our Clients the services
          requested; to comply with our legal obligations; or to protect against
          or prevent actual or potential fraud, criminal activity, claims,
          security threats, or liability.
        </li>
      </ul>
      <p>
        <strong>What other parties are involved?</strong>
      </p>
      <ul>
        <li>
          The software we use to process audio into written transcripts is
          Microsoft’s Azure Cognitive Services. The software we use to provide
          artificial intelligence summaries to our Clients is Azure OpenAI.
          Microsoft is a U.S. company. You can review Microsoft's privacy
          practices
          <a
            href="https://azure.microsoft.com/en-in/support/legal/cognitive-services-compliance-and-privacy/"
            >here</a
          >.
        </li>
        <li>
          Sometimes, we utilize contractors and other service providers to
          assist in our provision of VOE and related services. These companies
          or individuals are authorized to use your Biometric Data only as
          necessary to provide their services to us. One example is that we
          sometimes use third party providers to help us make “clips” or “reels”
          of videos, which Clients could use for their brand research and
          insights, advertising, and/or promotions. You can review our full list
          of subprocessors
          <a href="https://suzy.com/subprocessor-list">here</a>.
        </li>
        <li>
          You should also consult the privacy notices associated with your
          mobile device (<em>i.e.</em>, the device you use to record the video).
          For example, if you use an iPhone to record and submit VOE recordings,
          you should consult Apple’s applicable privacy notice.
        </li>
      </ul>
    </div>
  </ng-container>
</div>

<h2 id="your-privacy-choices">Your Privacy Choices</h2>
<p>
  We will not collect, use, store, or share your Biometric Data without your
  consent, and you always have the right to change your mind.
</p>
<ul>
  <li>
    <strong>For Photo Verify</strong>, participation is completely optional. If
    you don’t want to provide Biometric Data, don’t! Instead, select the option
    to verify your account using ReCAPTCHA. You won’t be penalized for doing so,
    although the approval process may take a little longer.
  </li>
  <li>
    <strong>For IDIs and Focus Groups</strong>, participation is completely
    optional. If you don’t want to participate, don’t! Simply reject the
    invitation(s). You won’t be penalized for doing so, although you will
    forfeit the associated rewards/points. If you are a Crowdtap Member, you can
    also change your preferences in your Profile to say you do not want to
    receive any IDI or Focus Group invitations. If you do, it just means you
    won’t be targeted for future ones.
  </li>
  <li>
    <strong>For VOEs</strong>, participation is completely optional. If you
    don’t want to submit a video recording, don’t! Simply skip the question(s).
    You won’t be penalized for doing so, although you will forfeit the
    associated rewards/points. If you are a Crowdtap Member, in the future, you
    also may be able to change your preferences in your Profile to say you do
    not want to receive any VOEs. If you were to do so, it would just mean you
    wouldn’t be targeted for future surveys involving VOEs.
  </li>
</ul>
<p>
  Finally, you can always exercise your privacy rights as detailed in our main
  Privacy Policy.
</p>
<h2 id="notice-to-illinois-residents">Notice to Illinois Residents</h2>
<p>
  We do not collect, use, and sell “biometric information” from Illinois
  residents, as that term is defined under the Illinois Biometric Protection
  Act. You must notify us if your residency changes to or from Illinois. You can
  do so by updating your profile information in Crowdtap, contacting
  <a href="https://support.crowdtap.com/hc/en-us/requests/new"
    >Crowdtap Support</a
  >, or emailing <a href="mailto:trust@suzy.com">trust@suzy.com</a>.
</p>
<h2 id="contact-us">
  <a name="_us6j9yspf3wr"></a>
  Contact Us
</h2>
<ul>
  <li>By email: <a href="mailto:trust@suzy.com">trust@suzy.com</a></li>
  <li>
    <a href="https://crowdtap.formcrafts.com/privacyrequest">
      submitting a Privacy Request
    </a>
  </li>
  <li>
    <a href="https://support.crowdtap.com/hc/en-us/requests/new">
      contacting Customer Support; or
    </a>
  </li>
  <li>
    writing to: Suzy, Inc., Attn: Legal, 228 Park Avenue South, PMB 85529
    Broadway, New York, NY 10003.
  </li>
  <li>
    If you are in the European Union, European Economic Area or UK, you can
    <a href="https://suzy.com/s/SUZY01-Representative-contact-summary-2023.pdf"
      >contact our Article 27 Data Protection Representative</a
    >.
  </li>
</ul>
<p>
  Suzy’s Legal Department is responsible for the organization’s compliance with
  this Notice.
</p>
<h2 id="changes-to-notice">Changes to Notice</h2>
<p>
  We may modify this Notice at any time, but if we do so, we will notify you by
  publishing the changes on this website and amending the date at the top of
  this page. If we determine the changes are material, we will provide you with
  additional, prominent notice (such as via email) as is appropriate under the
  circumstances or applicable laws.
</p>
<p>Prior Versions of this Notice</p>
<ul>
  <li>
    <a routerLink="/biometric-data-policy/2023-07-19">July 19, 2023</a>
  </li>
  <li>
    <a routerLink="/biometric-data-policy/2022-09-12">September 12, 2022</a>
  </li>
</ul>
