import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  HostBinding
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PipingTokenService } from './piping-token.service';
import { PipeKind } from '@asksuzy/typescript-sdk';

@Component({
  selector: 'app-piping-token',
  templateUrl: './piping-token.component.html',
  styleUrls: ['./piping-token.component.scss']
})
export class PipingTokenComponent implements OnInit, OnChanges, OnDestroy {
  private labelSub?: Subscription;
  private refreshSub!: Subscription;
  label: string;

  @Input('data-caller-id') callerMissionId: string;
  @Input('data-mission-id') missionId: string;
  @Input('data-action-id') actionId: string;
  @Input('data-pipe-kind') pipeKind = PipeKind.selected_responses;

  @Input('data-hide-icon') hideIcon: boolean = false;
  @Input('data-hide-priority') hidePriority: boolean = false;
  @HostBinding('class.plain') @Input('data-plain') plain: boolean = false;
  @Input('data-view-id') viewActionId: string;
  @Input('data-tooltip') tooltip: string;

  @Output() edit = new EventEmitter<{
    missionId: string;
    actionId: string;
    pipeKind: number;
  }>();

  constructor(private pipingTokenService: PipingTokenService) {}

  getLabel(): void {
    if (!this.missionId || !this.actionId) {
      return;
    }

    if (this.labelSub) {
      this.labelSub.unsubscribe();
    }

    let kind = this.pipeKind;
    if (typeof kind === 'string') {
      kind = Number(kind);
    }

    let hidePriority = this.hidePriority;
    if (typeof hidePriority === 'string') {
      hidePriority = hidePriority === 'true';
    }

    this.labelSub = this.pipingTokenService
      .getLabel$(
        this.missionId,
        this.actionId,
        kind,
        this.callerMissionId,
        hidePriority
      )
      .subscribe((label: string) => {
        this.label = label;
      });
  }

  ngOnInit(): void {
    this.refreshSub = this.pipingTokenService.onRefresh$().subscribe(() => {
      this.getLabel();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes['missionId'] &&
      !changes['actionId'] &&
      !changes['callerMissionId'] &&
      !changes['pipeKind']
    ) {
      return;
    }

    if (!this.missionId || !this.actionId) {
      this.label = '';
    } else {
      this.getLabel();
    }
  }

  ngOnDestroy(): void {
    if (this.labelSub) {
      this.labelSub.unsubscribe();
    }

    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }
}
