import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Subject, Observable } from 'rxjs';
import {
  CampaignKeysName,
  CampaignKeys,
  PanelKeysName,
  PanelKeys,
  SegmentService,
  ReferralKeysName
} from '@suzy/shared/data-access/tracking';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { Title } from '@angular/platform-browser';
import { SettingsService } from '../../../core/services/settings.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PasswordValidators } from '../signup/account-creation-email/PasswordValidators';
import { ISignupUser, SignupService } from '../signup/signup.service';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import {
  FacebookLoginProvider,
  SocialAuthService
} from 'angularx-social-login';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  @ViewChild('passwordFields', { static: true }) passwordFields: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  el_passwordFields: HTMLElement;
  el_wrapper: HTMLElement;

  get isMobile(): boolean {
    const isMobileLayoutMediaQuery = window.matchMedia('(max-width: 767px)');
    return isMobileLayoutMediaQuery.matches;
  }

  referralSignUpForm: UntypedFormGroup;
  user: ISignupUser;
  submitted = false;
  eyeView: boolean;
  isChange = false;
  activeUser: string;
  isLoading: boolean;
  grecaptchaToken: string;
  isReferralLandingPage: Boolean = false;
  campaignKeysName: CampaignKeysName;
  currentCampaignKeys: any;
  currentPanelKeys: any;
  currentPromoKeys: any;
  currentPanelToken: any;
  currentSource: any;
  panelKeysName: PanelKeysName;
  sourceUrl: string;
  currentYear: number;
  showMenu: boolean;
  currentReferralToken: any;
  currentReferralKeys: any;
  newReferralToken: any;
  referralKeysName: ReferralKeysName;
  isSpanish = false;
  promotionToken: string;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private titleService: Title,
    private signupService: SignupService,
    private segmentService: SegmentService,
    private gtm: Angulartics2GoogleTagManager,
    private socialAuthService: SocialAuthService,
    private settingsService: SettingsService
  ) {
    let language = navigator.language.toLowerCase();
    language = language.split('-')[0];
    this.isSpanish = language === 'es';
  }

  ngOnInit(): void {
    this.settingsService.bypassDarkMode(false);
    this.checkReferralToken();
    this.user = this.signupService.getUser();
    this.createForm();

    this.setHeadTitle();
    this.currentYear = new Date().getFullYear();
    this.setHeadTitle();

    this.checkCampaignKeys();
    this.checkPanelToken();

    this.getPromotionToken();
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap®');
  }

  getPromotionToken(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.promotionToken = params['promotion_token'];
    });
  }

  checkReferralToken(): void {
    this.referralKeysName = new ReferralKeysName();
    this.currentReferralToken = this.auth.getLocalKey(
      this.referralKeysName.referralToken
    );
    this.setNewReferralKeys();
    this.currentReferralKeys = this.auth.getCurrentReferralKeys();
  }

  setNewReferralKeys(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params[this.referralKeysName.referralToken]) {
        for (const prop in params) {
          if (prop === this.referralKeysName.referralToken) {
            this.newReferralToken = params[prop];
            if (!this.currentReferralToken) {
              this.auth.setLocalKey(prop, this.newReferralToken);
            } else {
              if (this.currentReferralToken !== this.newReferralToken) {
                this.auth.setLocalKey(prop, this.newReferralToken);
              }
            }
            this.currentReferralKeys = this.auth.getCurrentReferralKeys();
          }
        }
      } else {
        this.auth.purgeReferralKeys();
      }
    });
  }

  checkCampaignKeys(): void {
    this.campaignKeysName = new CampaignKeysName();
    this.currentSource = this.auth.getLocalKey(this.campaignKeysName.utmSource);
    this.sourceUrl = this.auth.getLocalKey(this.campaignKeysName.source);

    if (!this.sourceUrl) {
      const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;

      if (snapshot.url.includes('?')) {
        this.auth.setLocalKey(
          this.campaignKeysName.source,
          snapshot.url.substring(snapshot.url.indexOf('?'))
        );
      }
    }

    if (!this.currentSource) {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params[this.campaignKeysName.utmSource]) {
          for (const prop in params) {
            if (params[prop]) {
              this.auth.setLocalKey(prop, params[prop]);
            }
          }
          this.currentCampaignKeys = this.auth.getCurrentCampaignKeys();
        }
      });
    } else {
      this.currentCampaignKeys = this.auth.getCurrentCampaignKeys();
    }
  }

  checkPanelToken(): void {
    this.panelKeysName = new PanelKeysName();
    this.currentPanelToken = this.auth.getLocalKey(
      this.panelKeysName.panelToken
    );

    if (!this.currentPanelToken) {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params[this.panelKeysName.panelToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.auth.setLocalKey(prop, params[prop]);
            }
          }
          this.currentPanelKeys = this.auth.getCurrentPanelKeys();
        }
      });
    } else {
      this.currentPanelKeys = this.auth.getCurrentPanelKeys();
    }
  }

  createForm(): void {
    this.referralSignUpForm = this.fb.group({
      email: [
        this.user ? this.user.email : '',
        [Validators.required, Validators.email]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          PasswordValidators.password
        ]
      ],
      remember_me: [false, []]
    });
  }

  fieldErrors(name: string): any {
    const control = this.referralSignUpForm.get(name);

    if (control && (control.touched || this.submitted) && control.errors) {
      return control.errors;
    } else {
      return undefined;
    }
  }

  isFieldTouched(field): boolean {
    return this.referralSignUpForm.get(field).touched;
  }

  onChangeHandler(e): void {
    this.checkInput(e);
  }

  checkInput(e): void {
    if (e.target.value.length > 0) {
      this.el_passwordFields.classList.add('ng-changed');
      this.isChange = true;
    } else {
      this.el_passwordFields.classList.remove('ng-changed');
      this.isChange = false;
    }
  }

  toggleVisibility(e): void {
    if (e.target.nextElementSibling.type === 'password') {
      e.target.nextElementSibling.type = 'text';
      e.target.classList.add('isVisible');
    } else {
      e.target.nextElementSibling.type = 'password';
      e.target.classList.remove('isVisible');
    }
  }

  hasValue(field): boolean {
    return this.referralSignUpForm.get(field).value;
  }

  continueWithEmail(): void {
    this.submitted = true;
    if (this.referralSignUpForm.valid) {
      const account = this.referralSignUpForm.value;
      this.isLoading = true;

      this.signupService.setUser({
        ...this.user,
        email: account.email,
        password: account.password,
        progressStatus: 15
      });
      this.gtm.eventTrack('js-home-sign-up-success', {
        event: 'js-home-sign-up-success',
        gtmCustom: {
          is_fb_registered: false,
          is_email_registered: true
        }
      });
      this.segmentService.identify(undefined, {
        email: account.email
      });
      this.segmentService.track('Sign Up Initiated', {
        method: 'email'
      });

      this.router.navigate(['auth', 'get-to-know-you']);
    }
  }

  socialSignIn(socialPlatform: string): void {
    let socialPlatformProvider;
    this.isLoading = true;

    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      this.isLoading = false;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      this.auth
        .authenticateFacebookUser(userData, this.grecaptchaToken)
        .subscribe(response => {
          this.isLoading = false;

          if (this.currentPanelKeys && response.item) {
            this.currentPanelKeys = this.auth.getCurrentPanelKeys();
            response.item.panel_token = this.currentPanelKeys?.panelToken;
          }

          if (this.currentReferralKeys && response.item) {
            this.currentReferralKeys = this.auth.getCurrentReferralKeys();
            response.item.referral_token =
              this.currentReferralKeys?.referral_token;
          }

          if (this.currentPromoKeys && response.item) {
            this.currentReferralKeys = this.auth.getCurrentPromoKeys();
            response.item.promotion_token =
              this.currentReferralKeys?.promotion_token;
          }

          if (response.success) {
            this.gtm.eventTrack('js-home-fb-login-success', {
              event: 'js-home-fb-login-success',
              gtmCustom: {
                is_fb_registered: true,
                is_email_registered: false
              }
            });

            if (this.currentCampaignKeys) {
              response.item.source = this.currentCampaignKeys.source;
              response.item.c_source = this.currentCampaignKeys.utmSource;
              response.item.c_medium = this.currentCampaignKeys.utmMedium;
              response.item.c_name = this.currentCampaignKeys.utmName;
              response.item.c_terms = this.currentCampaignKeys.utmTerm;
              response.item.c_content = this.currentCampaignKeys.utmContent;
              response.item.c_misc_one = this.currentCampaignKeys.miscOne;
              response.item.c_misc_two = this.currentCampaignKeys.miscTwo;
            }

            this.auth.login(response.item);
            this.auth.purgeCampaignKeys();
            this.router.navigate(['dashboard']);
          } else if (response.meta) {
            this.gtm.eventTrack('js-home-fb-auth-success', {
              event: 'js-home-fb-auth-success'
            });
            this.signupService.setUser({
              ...response.meta,
              ...{ facebook_access_token: userData.authToken }
            });
            this.auth.purgePanelKeys();
            this.auth.purgeCampaignKeys();
            this.router.navigate(['auth', 'get-to-know-you']);
          } else {
            this.gtm.eventTrack('js-home-fb-auth-failed', {
              event: 'js-home-fb-auth-failed'
            });

            Swal.fire({
              buttonsStyling: false,
              confirmButtonText: 'Continue',
              confirmButtonClass: 'btn-confirm',
              customClass: 'general-modal',
              showConfirmButton: true,
              text: response.message,
              title: 'Error authenticating facebook user',
              type: 'warning'
            });
          }
        });
    });
    // Since we didn't get any response when there is a facebook issue, I added this for temporary solution.
    setTimeout(() => {
      this.isLoading = false;
    }, 1000 * 60);
  }

  becomeMember(): void {
    this.router.navigate(['/auth/account-create-email'], {
      queryParams: { promotion_token: this.promotionToken }
    });
  }

  login(): void {
    this.router.navigateByUrl('/auth/login');
  }
}
