import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValidNumber } from 'libphonenumber-js';

export class AppInputValidators {
  static phone(control: AbstractControl): ValidationErrors | undefined {
    if (!isValidNumber(control.value, 'US')) {
      return { invalidPhone: { value: control.value } };
    }
  }

  static phoneUS(control: AbstractControl): ValidationErrors | undefined {
    const usPhoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValid = usPhoneNumberPattern.test(control?.value ?? '');
    if (!isValid) {
      return { invalidPhone: { value: control?.value ?? '' } };
    }
  }
}
