<form novalidate="" (ngSubmit)="onFormSubmit($event)" class="h-full">
  <!-- Form Content Starts -->
  <div class="form">
    <ng-container *ngIf="!isSpecialMission">
      <div class="py-4 pt-10 form-group form-filling">
        <div class="relative">
          <textarea
            type="text"
            class="w-full p-4 text-black rounded-lg h-52 dark:text-body-light bg-body-light dark:bg-body-dark form-control"
            name="response"
            id="response"
            rows="6"
            maxlength="{{ action.open_ended.max_characters }}"
            placeholder="{{
              action.open_ended.placeholder || 'Your answer here'
            }}"
            [(ngModel)]="response"
            (keyup)="onResponseKeyup()"
            data-track="member-open-ended-response-field"
            required
          ></textarea>
          <p
            class="absolute text-sm text-black right-8 bottom-6 dark:text-body-light"
            *ngIf="action.open_ended.max_characters"
          >
            {{ response.length }}/
            {{ action.open_ended.max_characters }}
          </p>
        </div>
        <p
          class="flex items-center my-6 error-inline-msg text-error"
          *ngIf="isErrorMessage"
        >
          <svg
            class="inline-block mr-2"
            width="24"
            height="24"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.50004 4.04351C8.06282 4.04351 7.70837 4.39795 7.70837 4.83518V8.00185C7.70837 8.43907 8.06282 8.79351 8.50004 8.79351C8.93727 8.79351 9.29171 8.43907 9.29171 8.00185V4.83518C9.29171 4.39795 8.93727 4.04351 8.50004 4.04351ZM9.22837 10.8677C9.21104 10.8173 9.18708 10.7694 9.15712 10.7252L9.06212 10.6065C8.83447 10.3818 8.49402 10.3163 8.1992 10.4402C8.10326 10.4803 8.0149 10.5366 7.93795 10.6065C7.78976 10.7559 7.70715 10.9581 7.70837 11.1686C7.70962 11.272 7.73114 11.3742 7.7717 11.4694C7.84368 11.6683 8.00031 11.8249 8.1992 11.8969C8.39082 11.9816 8.60925 11.9816 8.80087 11.8969C8.99976 11.8249 9.15639 11.6683 9.22837 11.4694C9.26894 11.3742 9.29045 11.272 9.2917 11.1686C9.29559 11.1159 9.29559 11.0629 9.2917 11.0102C9.27807 10.9597 9.25671 10.9117 9.22837 10.8677ZM8.50004 0.0852051C4.12779 0.0852051 0.583374 3.62962 0.583374 8.00187C0.583374 12.3741 4.12779 15.9185 8.50004 15.9185C12.8723 15.9185 16.4167 12.3741 16.4167 8.00187C16.4167 5.90224 15.5826 3.88861 14.098 2.40394C12.6133 0.91928 10.5997 0.0852051 8.50004 0.0852051ZM8.50005 14.3352C5.00225 14.3352 2.16672 11.4996 2.16672 8.00185C2.16672 4.50404 5.00225 1.66851 8.50005 1.66851C11.9979 1.66851 14.8334 4.50404 14.8334 8.00185C14.8334 9.68155 14.1661 11.2925 12.9784 12.4802C11.7907 13.6679 10.1798 14.3352 8.50005 14.3352Z"
              fill="#FF5F57"
            />
          </svg>

          {{ 'unauthorized.yourAccountFlagged' | translate }}
          <a
            href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
            target="_blank"
            >{{ 'unauthorized.clickHereMission' | translate }}</a
          >.
        </p>
      </div>
    </ng-container>
    <div
      [ngClass]="{ 'pb-6': !isSpecialMission }"
      class="relative flex items-center justify-center space-x-2 align-middle"
    >
      <button
        class="w-36 text-blue-dark skip"
        (click)="onFormSkip($event)"
        (mousemove)="onMouseMove($event)"
        data-track="member-open-ended-skip"
        *ngIf="!disableSkip && !isSpecialMission"
        [ngClass]="{ loading: isSkipping }"
        [disabled]="isSkipping"
      >
        <span class="btn-label"> {{ 'general.skip' | translate }}</span>
        <span class="dot dot-1"></span>
        <span class="dot dot-2"></span>
        <span class="dot dot-3"></span>
      </button>

      <button
        class="animation-submit-btn {{
          isSpecialMission ? 'specialMission' : ''
        }}"
        [ngClass]="{
          primarySection: !isLoading && !isSuccess,
          loadingSection: isLoading,
          successSection: isSuccess
        }"
        (click)="onSubmitClick()"
        (mousemove)="onMouseMove($event)"
        data-track="member-open-ended-submit"
        [disabled]="isSkipping || isSubmitting || isLoading"
      >
        <ng-container *ngIf="!isSpecialMission; else submitBtn">
          <div class="primaryTitle text submit-label">
            {{ 'general.submit' | translate }}
          </div>
        </ng-container>
        <ng-template #submitBtn>
          <div class="primaryTitle text submit-label">
            {{ 'general.continue' | translate }}
          </div>
        </ng-template>
        <div class="primaryTitle text continue-label">
          {{ 'general.continue' | translate }}
        </div>
        <div class="loadingTitle text">
          <suzy-spinner
            class="absolute left-0 right-0 button-spinner"
            [ngClass]="!isSpecialMission ? 'top-3' : 'top-0.5'"
            size="small"
            button="true"
            [round]="7"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
        </div>
        <div class="successTitle text">
          <svg class="animated-check" viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
          </svg>
        </div>
      </button>
    </div>
  </div>
  <!-- Form Content Ends -->
</form>
