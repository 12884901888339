import {
  AfterContentChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { GlobalIsolateSdkService } from '@suzy/shared/data-access/global-isolate-sdk';
import {
  MissionKind,
  PreRollKind,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { LightboxComponent } from '@suzy/shared/ui/lightbox';
import { Subject } from 'rxjs';
import { MetaData } from '../../../core/models/meta-data.model';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { MetaDataService } from '../../../core/services/meta-data.service';
import { MissionExpiredService } from '../../../core/services/mission-expired/mission-expired.service';
import { SettingsService } from '../../../core/services/settings.service';
import { GlobalService } from '../../global/global.service';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { SkipModalService } from '../../../core/services/skip-modal-service';
import { beforeModalDismiss } from '../actions.service';
import { take } from 'rxjs/operators';

export interface AnswerChoice {
  col: any;
  row: any;
  isSelected: boolean;
  hasError: boolean;
}

interface FakeCorner {
  top: string;
  left: string;
  width: string;
  height: string;
  isSet: boolean;
}

@Component({
  selector: 'app-grid-open',
  templateUrl: './grid-open.component.html',
  styleUrls: ['./grid-open.component.scss']
})
export class GridOpenComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild('f') responseForm: NgForm;
  action: any = {};
  mission: any = {};
  subject: Subject<any> = new Subject<any>();
  rows = [];
  cols = [];
  rowErrors = [];
  colErrors = [];
  allChoices = [];
  errorMessage = '';
  activeColor = '';
  isSuccess = false;
  isLoading = false;
  hasPreroll = false;
  prerollViewed = false;
  isSubmitting = false;
  preroll_image: number = PreRollKind.image;
  preroll_link: number = PreRollKind.link;

  preRollKind = PreRollKind.none;
  preRollCaption = '';
  preRollUrl = '';

  metaData: MetaData;
  startDate: number;
  timeToAction: number;
  clientX: number;
  clientY: number;
  disableSkip: boolean;
  startTime: number;
  isSkipping: boolean;
  missionKind = MissionKind;

  @ViewChild('originalCorner') originalCorner: ElementRef<HTMLDivElement>;
  fakeCorner: FakeCorner;
  @ViewChild('gridOpen') gridOpen: any;
  @ViewChild('modalBody') modalBody: ElementRef<HTMLDivElement>;

  private trackActionName = true;

  constructor(
    private suzySDK: SuzySdkService,
    private globalSDK: GlobalIsolateSdkService,
    private alerts: AppAlertService,
    private missionService: MissionService,
    private skipModalService: SkipModalService,
    private modals: NgbModal,
    private renderer: Renderer2,
    private metaDataService: MetaDataService,
    private segmentService: SegmentService,
    private missionExpiredService: MissionExpiredService,
    private hostElement: ElementRef,
    private globalService: GlobalService,
    private settingsService: SettingsService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.fakeCorner = {
      top: '0',
      left: '0',
      width: '0',
      height: '0',
      isSet: false
    };
    this.startDate = Date.now();
    this.startTime = new Date().getTime();
    this.trackActionName = this.launchDarklyService.getGLB723Flag();
    this.metaData = {
      userId: '',
      type: 'grid_open',
      actionId: undefined,
      missionId: undefined,
      text: this.action.grid.question,
      response: undefined,
      attempt: this.startDate,
      startDateTime: this.startDate,
      actionStartDateTime: this.startDate,
      completedDateTime: undefined,
      dwellingTime: undefined,
      completionTime: undefined,
      interactionDateTime: undefined,
      closedDateTime: undefined,
      skipDateTime: undefined,
      skipFlag: false,
      mousePosition: undefined,
      deviceInfo: undefined,
      validationCount: 0,
      questionCounts: undefined,
      responseCount: undefined,
      numberOfOptions: this.action.grid.rows.length,
      optionPositions: undefined
    };
    const columnsChoices = this.action.grid.columns;
    const rowsChoices = this.action.grid.rows;

    for (let i = 0; i < columnsChoices.length; i++) {
      const currentCol = columnsChoices[i];

      if (!this.cols[i]) {
        this.cols[i] = [];
      }

      for (let n = 0; n < rowsChoices.length; n++) {
        const currentRow = rowsChoices[n];
        const choice = {
          col: currentCol,
          row: currentRow,
          value: {
            answer_id_column: currentCol.answer_id,
            answer_id_row: currentRow.answer_id
          },
          isSelected: false
        };

        if (!this.rows[n]) {
          this.rows[n] = [];
        }

        this.cols[i].push(choice);
        this.rows[n].push(choice);
        this.rowErrors[n] = false;
        this.allChoices.push(choice);
      }
      this.colErrors[i] = false;
    }

    this.preRollKind = this.action.preroll_kind;
    this.preRollCaption = this.action.preroll_instruction;

    if (
      this.action.preroll_kind !== 'undefined' &&
      this.action.preroll_kind !== PreRollKind.none
    ) {
      if (
        this.action.preroll_kind === PreRollKind.image &&
        this.action.preroll
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll.thumbs.full.url;
      } else if (
        this.action.preroll_kind === PreRollKind.link &&
        this.action.preroll_body
      ) {
        this.hasPreroll = true;
        this.preRollUrl = this.action.preroll_body;
      }
    }
    this.renderer.addClass(document.body, 'isGridAction');

    if (
      (this.mission.first_action && this.mission.first_action.prevent_skip) ||
      this.mission.mission_kind !== this.missionKind.standard
    ) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }

    this.segmentTrackActionStarted();
    this.activeColor = this.mission.color;
    const monadic_variant_id =
      this.action.has_monadic && this.action.monadic_variants.length === 1
        ? this.action.monadic_variants[0].monadic_variant_id
        : undefined;
    if (this.settingsService.isGlobalApp() && monadic_variant_id) {
      this.globalService.setMonadicVariantId(monadic_variant_id);
    }
    this.skipModalService.isSkip.pipe(take(1)).subscribe(data => {
      if (data === this.metaData.type) {
        this.skipAction();
      }
    });

    if (
      this.settingsService.isPreviewApp() ||
      this.settingsService.isExternalApp() ||
      this.settingsService.isGlobalApp()
    ) {
      window.scrollTo(0, 0);
    }
  }

  init(): void {
    // Do NOT remove
    // ActionLoaderComponent requires this
  }

  ngAfterViewInit(): void {
    if (!this.hasPreroll) {
      this.setGridHeaderPosition();
    }
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
    this.renderer.removeClass(document.body, 'isGridAction');
  }

  ngAfterContentChecked(): void {
    this.positionFakeCorner();
  }

  divScrolled(event: Event): void {
    this.gridOpen.divScrolled(event);
  }

  setGridHeaderPosition(): void {
    this.gridOpen?.setHeaderPosition(
      this.modalBody.nativeElement.offsetTop,
      this.modalBody.nativeElement.getBoundingClientRect().left
    );
  }

  segmentTrackActionStarted(): void {
    this.segmentService.trackActionStarted({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName ? this.action.grid.question : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      grid_kind: this.action.grid.grid_kind,
      preroll_kind: this.action.preroll_kind,
      image_answers: false,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      points_available:
        this.mission.action_count === 1
          ? this.mission.points
          : this.action.points_extra,
      tag: this.mission.specialType
    });
  }

  positionFakeCorner(reset = false): void {
    if (reset) {
      this.fakeCorner.isSet = false;
    }
    if (!this.fakeCorner.isSet && this.originalCorner) {
      const corner = this.originalCorner.nativeElement.getBoundingClientRect();
      this.fakeCorner.width = `${Math.ceil(corner.width)}px`;
      this.fakeCorner.height = `${Math.ceil(corner.height)}px`;
      this.fakeCorner.isSet = true;
    }
  }

  removeErrorPopover(): void {
    this.errorMessage = '';
  }

  onPrerollViewedEvent(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: false,
      image_attribuion: guid
    });
    this.prerollViewed = true;
    setTimeout(() => {
      this.setGridHeaderPosition();
    }, 50);
  }

  onViewPrerollImage(guid?: string): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true,
      image_attribuion: guid
    });
  }

  onViewPrerollLink(): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true
    });
  }

  chooseExactlyOneChoice(): boolean {
    return this.action.grid.open_row_max === 1;
  }

  chooseExactlyXChoices(): boolean {
    return (
      !this.chooseExactlyOneChoice() &&
      this.action.grid.open_row_min === this.action.grid.open_row_max
    );
  }

  chooseAtLeastXChoices(): boolean {
    return (
      this.action.grid.open_row_min != 1 &&
      this.action.grid.open_row_max === this.action.grid.columns.length &&
      this.action.grid.open_row_min < this.action.grid.columns.length
    );
  }

  chooseUpToXChoices(): boolean {
    return (
      this.action.grid.open_row_min === 1 && this.action.grid.open_row_max > 1
    );
  }

  chooseBetweenXandYChoices(): boolean {
    return (
      this.action.grid.open_row_min > 1 &&
      this.action.grid.open_row_max < this.action.grid.columns.length &&
      this.action.grid.open_row_min !== this.action.grid.open_row_max
    );
  }

  onUpdateMetaDataValidationCount(): void {
    this.metaData.validationCount++;
  }

  skipAction(): void {
    if (this.isSubmitting) {
      return;
    }
    this.sendMetaData('skip');
    this.isSubmitting = true;
    this.isSkipping = true;

    this.hostElement.nativeElement
      .closest('.modal')
      .classList.add('is-skipping');
    const input = {
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      action_id: this.action.action_id
    };
    this.suzySDK.ProtocolRespond.skip(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.skipModalService.isSuccess.next(true);
          setTimeout(() => {
            this.subject.next('skip');
            this.isSkipping = false;

            if (this.mission.action_count === 1) {
              this.segmentService.trackMission('Mission Skipped', {
                action_count: this.mission.action_count,
                brand_id: this.mission.brand_id,
                mission_id: this.mission.mission_id,
                points_available: this.mission.points,
                mission_kind: this.mission.mission_kind,
                has_cover: false,
                monadic_variant_quantity: this.mission.monadic_variants
                  ? this.mission.monadic_variants.length
                  : undefined,
                tag: this.mission.specialType,
                weight: this.mission.weight
              });
            }
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
          }, 1000);
        } else {
          this.skipModalService.isSuccess.next(false);
          let message = data.message;
          this.isSkipping = false;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.skipModalService.isSuccess.next(false);
        this.isSkipping = false;
        this.alerts.notify({ title: 'Error', message: error });
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, 'skip');
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = null;
    instance.actionType = this.metaData.type;
  }

  onSkip($event): void {
    $event.preventDefault();
    this.openSkipModal();
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.clientX = event.clientX;
    this.clientY = event.clientY;
  }

  onScrollEvent(event: Event): void {
    // nothing
  }

  onTimeToActionUpdate(eventData: number): void {
    this.timeToAction = eventData;
  }

  sendMetaData(actionType: string): void {
    let completedDateTime: number;
    let skipDateTime: number;
    let skipFlag: boolean;
    switch (actionType) {
      case 'submit':
        completedDateTime = Date.now();
        break;
      case 'skip':
        skipDateTime = Date.now();
        skipFlag = true;
        break;
      default:
        break;
    }

    const meta = {
      ...this.metaData,
      actionId: this.action.action_id,
      missionId: this.mission.mission_id,
      text: this.action.grid.question,
      completedDateTime,
      interactionDateTime: this.timeToAction,
      skipDateTime,
      skipFlag,
      mousePosition: { x: this.clientX, y: this.clientY },
      numberOfOptions: this.action.grid.rows.length
    };

    this.metaDataService.createMetaData(this.metaData);
  }

  onSubmit(): void {
    if (this.isSubmitting) {
      return;
    }
    this.sendMetaData('submit');
    this.segmentService.trackAction('Action Completed', {
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName ? this.action.grid.question : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      grid_kind: this.action.grid.grid_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      tag: this.mission.specialType
    });

    const selectedChoice = this.allChoices.filter(
      item => item.isSelected === true
    );

    const pairAnswers = selectedChoice.map(item => item.value);
    this.isSubmitting = true;
    this.isLoading = true;
    if (
      this.mission.isGlobal ||
      this.settingsService.isPreviewApp() ||
      this.settingsService.isGlobalApp() ||
      this.settingsService.isExternalApp()
    ) {
      const input: any = {
        action_id: this.action.action_id,
        answer_pairs: pairAnswers,
        correlation_data: this.action.correlation_data
          ? this.action.correlation_data
          : undefined
      };
      const monadic_variant_id = this.globalService.getMonadicVariantId();
      if (this.settingsService.isGlobalApp() && monadic_variant_id) {
        input.monadic_variant_id = monadic_variant_id;
      }
      this.globalResponse(input);
    } else {
      this.crowdtapResponse(pairAnswers);
    }
  }

  crowdtapResponse(pairAnswers): void {
    this.suzySDK.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      {
        action_id: this.action.action_id,
        answer_pairs: pairAnswers,
        correlation_data: this.action.correlation_data
          ? this.action.correlation_data
          : undefined
      }
    ).subscribe(
      data => {
        if (data.success) {
          this.isLoading = false;
          this.isSuccess = true;
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);
        } else {
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }

          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_code: data.error_code,
            error_source: 'backend',
            error_message: message,
            error_ui_message: undefined,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });

          this.isLoading = false;
          let errorCode = [404, 409, 401];
          if (errorCode.includes(data.error_code)) {
            this.openMissionExpiredModal(message);
          } else {
            this.errorMessage = message;
          }
        }
      },
      error => {
        this.isLoading = false;
        this.isSubmitting = false;
        let errorCode = [404, 409, 401];
        if (errorCode.includes(error.error_code)) {
          this.openMissionExpiredModal(error.message);
          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_code: error.error_code,
            error_source: 'backend',
            error_message: error.message,
            error_ui_message: undefined,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });
        } else {
          this.errorMessage = error;
          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_code: error.error_code,
            error_source: 'backend',
            error_message: this.errorMessage,
            error_ui_message: undefined,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });
        }
      }
    );
  }

  globalResponse(input): void {
    this.globalService
      .respondToGlobalActionAsync({
        payload: input
      })
      .subscribe(
        data => {
          if (data.success) {
            this.isLoading = false;
            this.isSuccess = true;
            setTimeout(() => {
              this.missionService.onMissionAnswered(
                this.mission.mission_id,
                data.meta
              );
              this.subject.next(data);
            }, 800);
          } else {
            let message = data.message;
            if (data.errors) {
              for (const error of Object.keys(data.errors)) {
                message += `\n - ${data.errors[error]}`;
              }
              this.errorMessage = message;
            }

            this.segmentService.trackActionError({
              action_id: this.action.action_id,
              action_kind: this.action.action_kind,
              brand_id: this.mission.brand_id,
              error_code: data.error_code,
              error_source: 'backend',
              error_message: message,
              error_ui_message: undefined,
              mission_id: this.mission.mission_id,
              mission_kind: this.mission.mission_kind,
              monadic_variant_id:
                this.action.has_monadic &&
                this.action.monadic_variants.length === 1
                  ? this.action.monadic_variants[0].monadic_variant_id
                  : undefined,
              tag: this.mission.specialType
            });

            this.isLoading = false;
            this.isSubmitting = false;
          }
        },
        error => {
          this.isLoading = false;
          this.isSubmitting = false;
        }
      );
  }
  openMissionExpiredModal(message: any) {
    this.missionExpiredService.notify({
      mission: this.mission,
      title: 'Error',
      message: message
    });
  }

  errorChange(error: string): void {
    if (!error) {
      return;
    }

    this.segmentService.trackValidationError({
      action_id: this.action.action_id,
      action_kind: this.action.action_kind,
      brand_id: this.mission.brand_id,
      error_code: undefined,
      error_source: 'frontend',
      error_message: error,
      error_ui_message: error,
      mission_id: this.mission.mission_id,
      mission_kind: this.mission.mission_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      tag: this.mission.specialType
    });
  }
}
