import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/';
import {
  SuzySdkService,
  OnboardingStatus
} from '@suzy/shared/data-access/suzy-sdk';

@Injectable()
export class UserProfileService {
  userData: any;
  localMachinePreferences: string;
  userUpdated$ = new Subject<any>();
  private storage: Storage;

  constructor(private suzySDK: SuzySdkService) {
    this.storage = localStorage;
  }

  setCurrentUser(user): void {
    if (user) {
      this.userData = user;
      this.localMachinePreferences = `localMachinePreferences-${this.userData.user_id}`;
    }
    this.userData = { ...this.userData, ...user };
    this.userUpdated$.next(this.userData);
  }

  getUserProfileThumbnail(): string {
    if (this.userData?.profile_photo && this.userData?.profile_photo?.thumbs) {
      return this.userData.profile_photo.thumbs.member.url;
    }
  }

  getCurrentUser(): any {
    return this.userData;
  }

  getAttribute(attr: string): any {
    if (this.userData) {
      if (this.userData.attributions) {
        return this.userData.attributions.find(value => value === attr);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getValidUserHandle(): string {
    if (this.userData.handle_verified_utc) {
      return this.userData.handle;
    } else {
      return '';
    }
  }

  updateProfile(profileId, userProfile): Observable<any> {
    return this.suzySDK.UserProfile.updateUserProfile(profileId, userProfile);
  }

  setUserLocalMachinePreferences(key, value): any {
    let localMachinePreferences = {};
    const storageValue = this.storage.getItem(this.localMachinePreferences);
    if (storageValue) {
      localMachinePreferences = JSON.parse(storageValue);
    }
    localMachinePreferences[key] = value;
    this.storage.setItem(
      this.localMachinePreferences,
      JSON.stringify(localMachinePreferences)
    );
  }

  getUserLocalMachinePreferences(key): any {
    const localMachinePreferences = this.storage.getItem(
      this.localMachinePreferences
    );
    if (localMachinePreferences) {
      return JSON.parse(localMachinePreferences)[key];
    } else {
      return undefined;
    }
  }

  getOnboardingStatus(status: number): string {
    switch (status) {
      case OnboardingStatus.completed:
        return 'Completed';
      case OnboardingStatus.grandfathered:
        return 'Grandfathered';
      case OnboardingStatus.onboarding:
        return 'Onboarding';
      case OnboardingStatus.trapped:
        return 'Trapped';
      default:
        return 'Unknown';
    }
  }

  mapSignupDataToUserProfileUpdate(data): any {
    return {
      user_profile_id: data.user_profile_id || null,
      user_id: data.user_id || null,
      ethnicity_id: data.ethnicity_id || null,
      region_id: data.region_id || null,
      state_id: data.state_id || null,
      city: data.city || null,
      zip_code: data.zip_code || null,
      birthdate: data.birthdate || null,
      profile_state: data.profile_state || 0,
      gender: data.gender || null,
      completion_percentage: data.completion_percentage || null,
      completion_missing: data.completion_missing || [],
      completion_optional_percentage:
        data.completion_optional_percentage || null,
      completion_optional_missing: data.completion_optional_missing || [],
      country_id: data.country_id || null,
      country_code: data.country_code || null,
      has_children: data.has_children || false,
      state_short: data.state_short || null,
      ethnicity_name: data.ethnicity_name || null,
      region_name: data.region_name || null,
      address1: data.address1 || null,
      address2: data.address2 || null,
      race_id: data.race_id || null,
      industry_id: data.industry_id || null,
      industry_title_id: data.industry_title_id || null,
      employment_id: data.employment_id || null,
      education_id: data.education_id || null,
      household_id: data.household_id || null,
      income_level_id: data.income_level_id || null,
      relationship_id: data.relationship_id || null,
      parenting_id: data.parenting_id || null,
      children_details: data.children_details || []
    };
  }
}
