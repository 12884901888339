import { CrowdtapEnvironment } from './definition';

export const environment: CrowdtapEnvironment = {
  production: false,
  preview: false,
  global: false,
  local: false,
  externalLink: true,
  apiUrl: 'https://api-survey-qa.crowdtap.com/api',
  globalUrl: 'https://isolate-qa.asksuzy.io/api',
  signalRUrl: 'https://ws-dev.asksuzy.io',
  facebookAppId: 'xxxxxxxxxx',
  grecaptchaToken: '6Lf6vD0oAAAAAGhXazGqiJoJ6m-KsM5mO__4mYT_',
  grecaptchaV2Token: '6LfIk20aAAAAADSvzkhMxhB0t3L1faUCXm1AqExS',
  languagePlatform: 'crowdtap.v1',
  segmentToken: 'KdQ36z0hTrDdFr1L46nZCRCDBP6JKB5G',
  sharedLinkRoot: 'https://survey-qa.suzy.com',
  launchDarkly: '60fec13ec07b0d23c5cab89f',
  legalId: '1248700B-3E07-48DF-9427-1885E1FE8213',
  verisoulBundleUri: undefined,
  verisoulProjectId: undefined
};
