import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MissionKind } from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { ActionsService } from '../../../views/actions/actions.service';
import { StoreService } from '../../../core/services/store.service';
import { SkipModalService } from '../../../core/services/skip-modal-service';

@Component({
  selector: 'app-skip-modal',
  templateUrl: './skip-modal.component.html',
  styleUrls: ['./skip-modal.component.scss']
})
export class SkipModalComponent implements OnInit {
  mission: any;
  action: any;
  actionType: string;
  kind: number;
  missionKind = MissionKind;
  isLoading = false;
  isSuccess = false;
  actionService: ActionsService;

  constructor(
    private renderer: Renderer2,
    public modals: NgbModal,
    public activeModal: NgbActiveModal,
    private segmentService: SegmentService,
    private skipModalService: SkipModalService,
    private storeService: StoreService,
    private hostElement: ElementRef
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'isActionModal');
    this.skipModalService.isSuccess.subscribe(data => {
      this.isLoading = false;
      if (data === true) {
        this.isSuccess = true;
      } else {
        this.isSuccess = false;
      }
    });
  }

  skip(): void {
    this.isLoading = true;
    if (this.actionType) {
      this.skipModalService.isSkip.next(this.actionType);
    } else {
      this.actionService.skipAction(this.action, this.mission).subscribe(
        result => {
          this.hostElement.nativeElement
            .closest('.modal')
            .classList.add('is-skipping');
          this.segmentService.trackMission('Mission Skipped', {
            action_count: this.mission.action_count,
            brand_id: this.mission.brand_id,
            mission_id: this.mission.mission_id,
            points_available: this.mission.points,
            mission_kind: this.kind,
            has_cover: true,
            monadic_variant_quantity: this.mission.monadic_variants
              ? this.mission.monadic_variants.length
              : undefined,
            tag: this.mission.specialType,
            weight: this.mission.weight
          });
          if (result) {
            this.isLoading = false;
            this.isSuccess = true;
            this.mission['status'] = true;
            this.mission['answer_status'] = 'skipped';
            setTimeout(() => {
              this.storeService.missionCompleted$.next({
                status: 'skipped',
                mission: this.mission
              });
            }, 1000);
          }
        },
        error => {
          this.isLoading = false;
        }
      );
    }
  }

  closeModal() {
    this.modals.dismissAll();
  }
  backModal() {
    this.activeModal.dismiss();
  }
}
