<form
  novalidate=""
  #f="ngForm"
  class="h-full"
  [ngClass]="{ 'legal-action': isLegalAction }"
>
  <!-- Form Content Starts -->
  <div
    class="flex flex-col h-full form"
    *ngIf="
      action.multiple_choice.multiple_choice_kind !== multipleChoiceKind.monadic
    "
  >
    <div
      class="text-sm form-group form-filling"
      [ngClass]="{
        'form-disabled': isAutoAssignOnPreview && !action.is_prerequisite
      }"
    >
      <!--Single Selection -->
      <ng-container *ngIf="!action.multiple_choice.image_answers">
        <!-- Single Selection -->
        <ng-container *ngIf="chooseExactlyOneChoice()">
          <p
            *ngIf="isLegalAction"
            class="font-semibold mb-4 sm:text-base md:text-lg"
          >
            {{ 'externalLink.legalCaption' | translate }}
          </p>
          <div
            class="flex items-start py-2 option-container"
            [ngClass]="{ 'mb-4': !last, 'mb-8': last }"
            *ngFor="
              let option of multipleChoice.options;
              let i = index;
              let last = last
            "
          >
            <ng-container
              *ngIf="
                !option.is_other && !option.is_none && !option.piped_is_none
              "
            >
              <label
                for="{{ 'radio-' + i }}"
                [attr.aria-labelledby]="'radio-' + i + '-label-container'"
                class="flex items-center"
                tabindex="0"
              >
                <input
                  class="sr-only"
                  name="options"
                  ng-control="options"
                  type="radio"
                  [value]="option.answer_id"
                  (change)="onRadioChange(option, $event, i)"
                  [(ngModel)]="selectedRadio"
                  id="{{ 'radio-' + i }}"
                  required
                />
                <div
                  *ngIf="!selectedRadio || selectedRadio !== option.answer_id"
                  class="radio-btn w-6 h-6 border-2 rounded-full min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                ></div>
                <div
                  *ngIf="selectedRadio && selectedRadio === option.answer_id"
                  class="radio-btn flex items-center justify-center w-6 h-6 rounded-full min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                >
                  <span class="text-xs text-white fa fa-check"></span>
                </div>
                <div
                  class="radio-label text-base font-semibold md:text-lg"
                  id="{{ 'radio-' + i + '-label-container' }}"
                >
                  {{ option.answer_text }}
                  <span
                    class="radio-icon"
                    [attr.data-track]="
                      'member-multiple-choice-answer-choice-' + i
                    "
                  >
                  </span>
                </div>
              </label>
            </ng-container>
            <ng-container *ngIf="option.is_other">
              <label
                for="{{ 'radio-' + i }}"
                [attr.aria-labelledby]="'radio-' + i + '-label-container'"
                class="flex items-center"
                tabindex="0"
              >
                <input
                  class="sr-only"
                  name="options"
                  ng-control="options"
                  type="radio"
                  [value]="option.answer_id"
                  (change)="onRadioChange(option, $event, i)"
                  [(ngModel)]="selectedRadio"
                  id="{{ 'radio-' + i }}"
                  required
                />
                <div
                  *ngIf="!selectedRadio || selectedRadio !== option.answer_id"
                  class="w-6 h-6 border-2 rounded-full min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                ></div>
                <div
                  *ngIf="selectedRadio && selectedRadio === option.answer_id"
                  class="flex items-center justify-center w-6 h-6 rounded-full min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                >
                  <span class="text-xs text-white fa fa-check"></span>
                </div>
                <div
                  class="text-base font-semibold md:text-lg"
                  id="{{ 'radio-' + i + '-label-container' }}"
                >
                  {{ multipleChoice.otherText || 'Other' }}
                  <span
                    class="radio-icon"
                    data-track="member-multiple-choice-other"
                  ></span>
                </div>
              </label>

              <div class="text-right rtl:text-left">
                <input
                  type="text"
                  name="other"
                  maxlength="100"
                  class="form-control bg-input dark:bg-black-light ml-7 ltr:ml-7 rtl:mr-7"
                  (input)="updateOtherValue('radio', option, $event, i)"
                  [(ngModel)]="radioInput"
                  id="{{ 'label-' + i }}"
                  data-track="member-multiple-choice-other-text"
                  autocomplete="off"
                />
                <div
                  [ngClass]="{
                    'text-error':
                      radioInput?.length == 100 ||
                      (option.answer_id == selectedRadio && errorMessage)
                  }"
                >
                  {{ radioInput?.length ? radioInput?.length : 0 }}/ {{ 100 }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="option.is_none || option.piped_is_none">
              <label
                for="{{ 'radio-' + i }}"
                [attr.aria-labelledby]="'radio-' + i + '-label-container'"
                class="flex items-center"
                tabindex="0"
              >
                <input
                  class="sr-only"
                  name="options"
                  ng-control="options"
                  type="radio"
                  [value]="option.answer_id"
                  autocomplete="off"
                  (change)="onRadioChange(option, $event, i)"
                  [(ngModel)]="selectedRadio"
                  id="{{ 'radio-' + i }}"
                  required
                />
                <div
                  *ngIf="!selectedRadio || selectedRadio !== option.answer_id"
                  class="w-6 h-6 border-2 rounded-full min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                ></div>
                <div
                  *ngIf="selectedRadio && selectedRadio === option.answer_id"
                  class="flex items-center justify-center w-6 h-6 rounded-full min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                >
                  <span class="text-xs text-white fa fa-check"></span>
                </div>
                <div
                  class="text-base font-semibold md:text-lg"
                  id="{{ 'radio-' + i + '-label-container' }}"
                >
                  {{ multipleChoice.noneText || 'None of the above' }}
                  <span
                    class="radio-icon"
                    data-track="member-multiple-choice-none-of-the-above"
                  >
                  </span>
                </div>
              </label>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="!chooseExactlyOneChoice()">
          <div
            class="flex items-start py-2 option-container"
            [ngClass]="{ 'mb-4': !last, 'mb-8': last }"
            *ngFor="
              let option of multipleChoice.options;
              let i = index;
              let last = last
            "
          >
            <ng-container
              *ngIf="
                !option.is_other && !option.is_none && !option.piped_is_none
              "
            >
              <label
                for="{{ 'checkbox-' + i }}"
                [attr.aria-labelledby]="'checkbox-' + i + '-label-container'"
                class="flex items-center"
                tabindex="0"
              >
                <input
                  class="sr-only"
                  type="checkbox"
                  [checked]="option.isSelected"
                  [value]="option.answer_id"
                  (change)="onCheckBoxChange(option, $event, i)"
                  id="{{ 'checkbox-' + i }}"
                />
                <div
                  *ngIf="!option.isSelected"
                  class="w-6 h-6 border-2 min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                ></div>
                <div
                  *ngIf="option.isSelected"
                  class="flex items-center justify-center w-6 h-6 min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                >
                  <span class="text-xs text-white fa fa-check"></span>
                </div>
                <div
                  class="text-base font-semibold md:text-lg"
                  id="{{ 'checkbox-' + i + '-label-container' }}"
                >
                  {{ option.answer_text }}
                  <span
                    class="check-icon"
                    [attr.data-track]="
                      'member-multiple-choice-answer-choice-' + i
                    "
                  >
                  </span>
                </div>
              </label>
            </ng-container>

            <ng-container *ngIf="option.is_other">
              <label
                for="{{ 'checkbox-' + i }}"
                [attr.aria-labelledby]="'checkbox-' + i + '-label-container'"
                class="flex items-center"
                tabindex="0"
              >
                <input
                  class="sr-only"
                  type="checkbox"
                  [checked]="option.isSelected"
                  [value]="option.answer_id"
                  (change)="onCheckBoxChange(option, $event, i)"
                  id="{{ 'checkbox-' + i }}"
                />
                <div
                  *ngIf="!option.isSelected"
                  class="w-6 h-6 border-2 min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                ></div>
                <div
                  *ngIf="option.isSelected"
                  class="flex items-center justify-center w-6 h-6 min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                >
                  <span class="text-xs text-white fa fa-check"></span>
                </div>
                <div
                  class="text-base font-semibold md:text-lg"
                  id="{{ 'checkbox-' + i + '-label-container' }}"
                >
                  {{ multipleChoice.otherText || 'Other' }}
                  <span
                    class="check-icon"
                    data-track="member-multiple-choice-other"
                  ></span>
                </div>
              </label>
              <div class="text-right ltr:text-right rtl:text-left">
                <input
                  type="text"
                  name="other"
                  maxlength="100"
                  class="form-control bg-input dark:bg-black-light ml-7 ltr:ml-7 rtl:mr-7"
                  (input)="updateOtherValue('checkbox', option, $event, i)"
                  [(ngModel)]="checkboxInput"
                  data-track="member-multiple-choice-other-text"
                  autocomplete="off"
                  id="{{ 'label-' + i }}"
                />
                <div
                  [ngClass]="{
                    'text-error':
                      checkboxInput?.length == 100 ||
                      (option?.isSelected && errorMessage)
                  }"
                >
                  {{ checkboxInput?.length ? checkboxInput?.length : 0 }}/
                  {{ 100 }}
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="option.is_none || option.piped_is_none">
              <label
                for="{{ 'checkbox-' + i }}"
                [attr.aria-labelledby]="'checkbox-' + i + '-label-container'"
                class="flex items-center"
                tabindex="0"
              >
                <input
                  class="sr-only"
                  type="checkbox"
                  [checked]="option.isSelected"
                  [value]="option.answer_id"
                  (change)="onCheckBoxChange(option, $event, i)"
                  id="{{ 'checkbox-' + i }}"
                />
                <div
                  *ngIf="!option.isSelected"
                  class="w-6 h-6 border-2 min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                ></div>
                <div
                  *ngIf="option.isSelected"
                  class="flex items-center justify-center w-6 h-6 min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                >
                  <span class="text-xs text-white fa fa-check"></span>
                </div>
                <div
                  class="text-base font-semibold md:text-lg"
                  id="{{ 'checkbox-' + i + '-label-container' }}"
                >
                  {{ multipleChoice.noneText || 'None of the above' }}
                  <span
                    class="check-icon"
                    data-track="member-multiple-choice-none-of-the-above"
                  >
                  </span>
                </div>
              </label>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <!-- Image As Answer Choices -->
      <ng-container *ngIf="action.multiple_choice.image_answers">
        <!--Single Selection -->

        <ng-container *ngIf="chooseExactlyOneChoice()">
          <div class="options-container">
            <ng-container
              *ngFor="let option of multipleChoice.options; let i = index"
            >
              <ng-container
                *ngIf="
                  !option.is_other && !option.is_none && !option.piped_is_none
                "
              >
                <div class="image-option-container">
                  <div class="image-container">
                    <span
                      class="expand-icon"
                      (click)="viewOptionImage(option, i)"
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_6423_239437)">
                          <path
                            d="M17.0059 0.998047H2.07342C1.22763 0.998047 0.5 1.66032 0.5 2.50593V17.4384C0.5 18.284 1.22763 18.998 2.07342 18.998H17.0059C17.8517 18.998 18.5 18.284 18.5 17.4384V2.50593C18.5 1.66032 17.8516 0.998047 17.0059 0.998047ZM17.7341 17.4643C17.7341 17.8883 17.3903 18.2321 16.9662 18.2321H2.0338C1.60971 18.2321 1.26598 17.8883 1.26598 17.4643V2.53184C1.26598 2.10782 1.60974 1.76403 2.0338 1.76403H16.9662C17.3903 1.76403 17.7341 2.10782 17.7341 2.53184V17.4643Z"
                            fill="currentColor"
                          />
                          <path
                            d="M14.8588 4.29614H11.029C10.8175 4.29614 10.646 4.46742 10.646 4.67913C10.646 4.89085 10.8175 5.06213 11.029 5.06213H13.9342L10.9923 7.99119C10.8427 8.14078 10.8427 8.37661 10.9923 8.5262C11.0671 8.60101 11.1651 8.63522 11.263 8.63522C11.361 8.63522 11.4392 8.59623 11.514 8.52142L14.4362 5.57773V8.48297C14.4362 8.69465 14.6076 8.86596 14.8191 8.86596C15.0306 8.86596 15.2021 8.69468 15.2021 8.48297V4.65322C15.2021 4.44151 15.0703 4.29614 14.8588 4.29614Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.46015 11.048C8.31056 10.8984 8.05828 10.892 7.90869 11.0415L4.94689 13.9835V11.0783C4.94689 10.8666 4.7754 10.6953 4.5639 10.6953C4.3524 10.6953 4.18091 10.8666 4.18091 11.0783V14.9081C4.18091 14.9332 4.20334 14.9583 4.20829 14.9831C4.21062 14.995 4.22496 15.006 4.2284 15.0175C4.2321 15.0298 4.23987 15.0425 4.24482 15.0544C4.25073 15.0686 4.2611 15.0815 4.26855 15.0948C4.27326 15.1032 4.27819 15.1121 4.28356 15.1202C4.31169 15.1624 4.3486 15.1987 4.39079 15.2268C4.39902 15.2322 4.40823 15.236 4.41677 15.2407C4.42996 15.2481 4.44296 15.256 4.45706 15.2619C4.46908 15.2668 4.48171 15.2696 4.49408 15.2734C4.50558 15.2767 4.51662 15.2941 4.5285 15.2965C4.55321 15.3014 4.57838 15.317 4.60349 15.317H8.43327C8.64477 15.317 8.81626 15.1457 8.81626 14.934C8.81626 14.7223 8.64477 14.551 8.43327 14.551H5.52803L8.47003 11.5961C8.61969 11.4465 8.60977 11.1976 8.46015 11.048Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6423_239437">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(0.5 0.998047)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>

                    <label for="{{ 'radio-' + i }}">
                      <img
                        [src]="
                          option.asset
                            ? option.asset?.thumbs.full.url
                            : option.asset_upload?.url
                        "
                        [alt]="option.asset_alt_text"
                      />
                    </label>
                  </div>
                  <div class="text-container">
                    <label
                      for="{{ 'radio-' + i }}"
                      [attr.aria-labelledby]="'radio-' + i + '-label-container'"
                      class="flex items-center text-sm"
                      tabindex="0"
                    >
                      <input
                        class="sr-only"
                        name="options"
                        ng-control="options"
                        type="radio"
                        [value]="option.answer_id"
                        (change)="onRadioChange(option, $event, i)"
                        [(ngModel)]="selectedRadio"
                        id="{{ 'radio-' + i }}"
                        required
                      />
                      <div
                        *ngIf="
                          !selectedRadio || selectedRadio !== option.answer_id
                        "
                        class="w-6 h-6 border-2 rounded-full min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                      ></div>
                      <div
                        *ngIf="
                          selectedRadio && selectedRadio === option.answer_id
                        "
                        class="flex items-center justify-center w-6 h-6 rounded-full min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                      >
                        <span class="text-xs text-white fa fa-check"></span>
                      </div>
                      <div
                        class="text-base font-semibold md:text-lg"
                        id="{{ 'radio-' + i + '-label-container' }}"
                      >
                        {{ option.answer_text }}
                        <span
                          class="radio-icon"
                          [attr.data-track]="
                            'member-multiple-choice-answer-choice-' + i
                          "
                        >
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="option.is_other">
                <div class="image-option-container">
                  <div class="image-container na-container">
                    <label for="{{ 'radio-' + i }}">
                      <span class="na text-box-dark dark:text-box-light"
                        >N/A</span
                      >
                    </label>
                  </div>
                  <div class="mt-4 text-container">
                    <label
                      for="{{ 'radio-' + i }}"
                      [attr.aria-labelledby]="'radio-' + i + '-label-container'"
                      class="flex items-center"
                      tabindex="0"
                    >
                      <input
                        class="sr-only"
                        name="options"
                        ng-control="options"
                        type="radio"
                        [value]="option.answer_id"
                        (change)="onRadioChange(option, $event, i)"
                        [(ngModel)]="selectedRadio"
                        id="{{ 'radio-' + i }}"
                        required
                      />
                      <div
                        *ngIf="
                          !selectedRadio || selectedRadio !== option.answer_id
                        "
                        class="w-6 h-6 border-2 rounded-full min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                      ></div>
                      <div
                        *ngIf="
                          selectedRadio && selectedRadio === option.answer_id
                        "
                        class="flex items-center justify-center w-6 h-6 rounded-full min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                      >
                        <span class="text-xs text-white fa fa-check"></span>
                      </div>
                      <div
                        class="text-base font-semibold md:text-lg"
                        id="{{ 'radio-' + i + '-label-container' }}"
                      >
                        {{ multipleChoice.otherText || 'Other' }}
                        <span
                          class="radio-icon"
                          data-track="member-multiple-choice-other"
                        ></span>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="option.is_none || option.piped_is_none">
                <div class="image-option-container">
                  <div class="image-container na-container">
                    <label for="{{ 'radio-' + i }}">
                      <span class="na">N/A</span>
                    </label>
                  </div>
                  <div class="mt-4 text-container">
                    <label
                      for="{{ 'radio-' + i }}"
                      [attr.aria-labelledby]="'radio-' + i + '-label-container'"
                      class="flex items-center text-sm"
                      tabindex="0"
                    >
                      <input
                        class="sr-only"
                        name="options"
                        ng-control="options"
                        type="radio"
                        [value]="option.answer_id"
                        autocomplete="off"
                        (change)="onRadioChange(option, $event, i)"
                        [(ngModel)]="selectedRadio"
                        id="{{ 'radio-' + i }}"
                        required
                      />
                      <div
                        *ngIf="
                          !selectedRadio || selectedRadio !== option.answer_id
                        "
                        class="w-6 h-6 border-2 rounded-full min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                      ></div>
                      <div
                        *ngIf="
                          selectedRadio && selectedRadio === option.answer_id
                        "
                        class="flex items-center justify-center w-6 h-6 rounded-full min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                      >
                        <span class="text-xs text-white fa fa-check"></span>
                      </div>
                      <div
                        class="text-base font-semibold md:text-lg"
                        id="{{ 'radio-' + i + '-label-container' }}"
                      >
                        {{ multipleChoice.noneText || 'None of the above' }}
                        <span
                          class="radio-icon"
                          data-track="member-multiple-choice-none-of-the-above"
                        >
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="!chooseExactlyOneChoice()">
          <div
            class="grid grid-cols-2 gap-8 mt-4 md:gap-16 md:grid-cols-3 options-container"
          >
            <ng-container
              *ngFor="let option of multipleChoice.options; let i = index"
            >
              <ng-container
                *ngIf="
                  !option.is_other && !option.is_none && !option.piped_is_none
                "
              >
                <div class="image-option-container">
                  <div class="relative image-container">
                    <span
                      class="absolute expand-icon bg-light-gray dark:bg-gray-dark text-box-dark dark:text-box-light right-1 ltr:right-1 rtl:left-1"
                      (click)="viewOptionImage(option)"
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_6423_239437)">
                          <path
                            d="M17.0059 0.998047H2.07342C1.22763 0.998047 0.5 1.66032 0.5 2.50593V17.4384C0.5 18.284 1.22763 18.998 2.07342 18.998H17.0059C17.8517 18.998 18.5 18.284 18.5 17.4384V2.50593C18.5 1.66032 17.8516 0.998047 17.0059 0.998047ZM17.7341 17.4643C17.7341 17.8883 17.3903 18.2321 16.9662 18.2321H2.0338C1.60971 18.2321 1.26598 17.8883 1.26598 17.4643V2.53184C1.26598 2.10782 1.60974 1.76403 2.0338 1.76403H16.9662C17.3903 1.76403 17.7341 2.10782 17.7341 2.53184V17.4643Z"
                            fill="currentColor"
                          />
                          <path
                            d="M14.8588 4.29614H11.029C10.8175 4.29614 10.646 4.46742 10.646 4.67913C10.646 4.89085 10.8175 5.06213 11.029 5.06213H13.9342L10.9923 7.99119C10.8427 8.14078 10.8427 8.37661 10.9923 8.5262C11.0671 8.60101 11.1651 8.63522 11.263 8.63522C11.361 8.63522 11.4392 8.59623 11.514 8.52142L14.4362 5.57773V8.48297C14.4362 8.69465 14.6076 8.86596 14.8191 8.86596C15.0306 8.86596 15.2021 8.69468 15.2021 8.48297V4.65322C15.2021 4.44151 15.0703 4.29614 14.8588 4.29614Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.46015 11.048C8.31056 10.8984 8.05828 10.892 7.90869 11.0415L4.94689 13.9835V11.0783C4.94689 10.8666 4.7754 10.6953 4.5639 10.6953C4.3524 10.6953 4.18091 10.8666 4.18091 11.0783V14.9081C4.18091 14.9332 4.20334 14.9583 4.20829 14.9831C4.21062 14.995 4.22496 15.006 4.2284 15.0175C4.2321 15.0298 4.23987 15.0425 4.24482 15.0544C4.25073 15.0686 4.2611 15.0815 4.26855 15.0948C4.27326 15.1032 4.27819 15.1121 4.28356 15.1202C4.31169 15.1624 4.3486 15.1987 4.39079 15.2268C4.39902 15.2322 4.40823 15.236 4.41677 15.2407C4.42996 15.2481 4.44296 15.256 4.45706 15.2619C4.46908 15.2668 4.48171 15.2696 4.49408 15.2734C4.50558 15.2767 4.51662 15.2941 4.5285 15.2965C4.55321 15.3014 4.57838 15.317 4.60349 15.317H8.43327C8.64477 15.317 8.81626 15.1457 8.81626 14.934C8.81626 14.7223 8.64477 14.551 8.43327 14.551H5.52803L8.47003 11.5961C8.61969 11.4465 8.60977 11.1976 8.46015 11.048Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6423_239437">
                            <rect
                              width="18"
                              height="18"
                              fill="white"
                              transform="translate(0.5 0.998047)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <label for="{{ 'checkbox-' + i }}">
                      <img
                        [src]="option.asset?.thumbs.full.url"
                        class="object-fill w-40 h-40 md:h-64 md:w-80"
                        [alt]="option.asset_alt_text"
                      />
                    </label>
                  </div>
                  <div class="mt-4 text-container">
                    <label
                      for="{{ 'checkbox-' + i }}"
                      [attr.aria-labelledby]="
                        'checkbox-' + i + '-label-container'
                      "
                      class="flex items-center text-sm"
                      tabindex="0"
                    >
                      <input
                        class="sr-only"
                        type="checkbox"
                        [checked]="option.isSelected"
                        [value]="option.answer_id"
                        (change)="onCheckBoxChange(option, $event, i)"
                        id="{{ 'checkbox-' + i }}"
                      />
                      <div
                        *ngIf="!option.isSelected"
                        class="w-6 h-6 border-2 min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                      ></div>
                      <div
                        *ngIf="option.isSelected"
                        class="flex items-center justify-center w-6 h-6 min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                      >
                        <span class="text-xs text-white fa fa-check"></span>
                      </div>
                      <div
                        class="text-base font-semibold md:text-lg"
                        id="{{ 'checkbox-' + i + '-label-container' }}"
                      >
                        {{ option.answer_text }}
                        <span
                          class="check-icon"
                          [attr.data-track]="
                            'member-multiple-choice-answer-choice-' + i
                          "
                        >
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="option.is_other">
                <div class="image-option-container">
                  <div class="image-container na-container">
                    <label for="{{ 'checkbox-' + i }}">
                      <span class="na">N/A</span>
                    </label>
                  </div>
                  <div class="mt-4 text-container">
                    <label
                      for="{{ 'checkbox-' + i }}"
                      [attr.aria-labelledby]="
                        'checkbox-' + i + '-label-container'
                      "
                      class="flex items-center"
                      tabindex="0"
                    >
                      <input
                        class="sr-only"
                        type="checkbox"
                        [checked]="option.isSelected"
                        [value]="option.answer_id"
                        (change)="onCheckBoxChange(option, $event, i)"
                        id="{{ 'checkbox-' + i }}"
                      />
                      <div
                        *ngIf="!option.isSelected"
                        class="w-6 h-6 border-2 min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                      ></div>
                      <div
                        *ngIf="option.isSelected"
                        class="flex items-center justify-center w-6 h-6 min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                      >
                        <span class="text-xs text-white fa fa-check"></span>
                      </div>
                      <div
                        class="text-base font-semibold md:text-lg"
                        id="{{ 'checkbox-' + i + '-label-container' }}"
                      >
                        {{ multipleChoice.otherText || 'Other' }}
                        <span
                          class="check-icon"
                          data-track="member-multiple-choice-other"
                        ></span>
                      </div>
                    </label>

                    <div class="text-right ltr:text-right rtl:text-left">
                      <input
                        type="text"
                        name="other"
                        maxlength="100"
                        class="form-control bg-input dark:bg-black-light ml-7 ltr:ml-7 rtl:mr-7"
                        (input)="
                          updateOtherValue('checkbox', option, $event, i)
                        "
                        data-track="member-multiple-choice-other-text"
                        autocomplete="off"
                        [(ngModel)]="checkboxInput"
                        id="{{ 'label-' + i }}"
                      />
                      <div
                        [ngClass]="{
                          'text-error':
                            checkboxInput?.length == 100 ||
                            (option?.isSelected && errorMessage)
                        }"
                      >
                        {{ checkboxInput?.length ? checkboxInput?.length : 0 }}/
                        {{ 100 }}
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="option.is_none || option.piped_is_none">
                <div class="image-option-container">
                  <div class="image-container na-container">
                    <label for="{{ 'checkbox-' + i }}">
                      <span class="na">N/A</span>
                    </label>
                  </div>
                  <div class="mt-4 text-container">
                    <label
                      for="{{ 'checkbox-' + i }}"
                      [attr.aria-labelledby]="
                        'checkbox-' + i + '-label-container'
                      "
                      class="flex items-center"
                      tabindex="0"
                    >
                      <input
                        class="sr-only"
                        type="checkbox"
                        [checked]="option.isSelected"
                        [value]="option.answer_id"
                        (change)="onCheckBoxChange(option, $event, i)"
                        id="{{ 'checkbox-' + i }}"
                      />
                      <div
                        *ngIf="!option.isSelected"
                        class="w-6 h-6 border-2 min-w-6 border-gray mr-4 ltr:mr-4 rtl:ml-4"
                      ></div>
                      <div
                        *ngIf="option.isSelected"
                        class="flex items-center justify-center w-6 h-6 min-w-6 bg-blue-dark mr-4 ltr:mr-4 rtl:ml-4"
                      >
                        <span class="text-xs text-white fa fa-check"></span>
                      </div>
                      <div
                        class="text-base font-semibold md:text-lg"
                        id="{{ 'checkbox-' + i + '-label-container' }}"
                      >
                        {{ multipleChoice.noneText || 'None of the above' }}
                        <span
                          class="check-icon"
                          data-track="member-multiple-choice-none-of-the-above"
                        >
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <p
        class="flex items-center my-6 error-inline-msg text-error"
        *ngIf="errorMessage"
      >
        <svg
          class="inline-block mr-2 ltr:mr-2 rtl:ml-2"
          width="24"
          height="24"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.50004 4.04351C8.06282 4.04351 7.70837 4.39795 7.70837 4.83518V8.00185C7.70837 8.43907 8.06282 8.79351 8.50004 8.79351C8.93727 8.79351 9.29171 8.43907 9.29171 8.00185V4.83518C9.29171 4.39795 8.93727 4.04351 8.50004 4.04351ZM9.22837 10.8677C9.21104 10.8173 9.18708 10.7694 9.15712 10.7252L9.06212 10.6065C8.83447 10.3818 8.49402 10.3163 8.1992 10.4402C8.10326 10.4803 8.0149 10.5366 7.93795 10.6065C7.78976 10.7559 7.70715 10.9581 7.70837 11.1686C7.70962 11.272 7.73114 11.3742 7.7717 11.4694C7.84368 11.6683 8.00031 11.8249 8.1992 11.8969C8.39082 11.9816 8.60925 11.9816 8.80087 11.8969C8.99976 11.8249 9.15639 11.6683 9.22837 11.4694C9.26894 11.3742 9.29045 11.272 9.2917 11.1686C9.29559 11.1159 9.29559 11.0629 9.2917 11.0102C9.27807 10.9597 9.25671 10.9117 9.22837 10.8677ZM8.50004 0.0852051C4.12779 0.0852051 0.583374 3.62962 0.583374 8.00187C0.583374 12.3741 4.12779 15.9185 8.50004 15.9185C12.8723 15.9185 16.4167 12.3741 16.4167 8.00187C16.4167 5.90224 15.5826 3.88861 14.098 2.40394C12.6133 0.91928 10.5997 0.0852051 8.50004 0.0852051ZM8.50005 14.3352C5.00225 14.3352 2.16672 11.4996 2.16672 8.00185C2.16672 4.50404 5.00225 1.66851 8.50005 1.66851C11.9979 1.66851 14.8334 4.50404 14.8334 8.00185C14.8334 9.68155 14.1661 11.2925 12.9784 12.4802C11.7907 13.6679 10.1798 14.3352 8.50005 14.3352Z"
            fill="#FF5F57"
          />
        </svg>
        {{ 'unauthorized.yourAccountFlagged' | translate }}
        <a
          href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
          target="_blank"
          >{{ 'unauthorized.clickHereMission' | translate }}</a
        >.
      </p>
    </div>
    <div *ngIf="isAutoAssignOnPreview && !action.is_prerequisite">
      <p>{{ 'surveyPreview.autoAssignDescription' | translate }}</p>
    </div>

    <div
      class="relative flex items-center justify-center pb-24 md:pb-6 space-x-2 text-sm font-bold align-middle md:text-base md:space-x-16 form-group form-cta"
    >
      <ng-container
        *ngIf="
          !action.prevent_skip &&
          mission.mission_kind !== missionKind.external_link
        "
      >
        <button
          type="button"
          class="w-36 text-blue-dark skip"
          (click)="onSkip($event)"
          data-track="member-multiple-choice-skip"
          (mousemove)="onMouseMoveEvent($event)"
          [disabled]="isTrapQuestion || disableSkip || isSkipping"
          *ngIf="disableButton"
          [ngClass]="{ loading: isSkipping }"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>
      </ng-container>

      <button
        class="animation-submit-btn"
        (mousemove)="onMouseMoveEvent($event)"
        (click)="onSubmit()"
        data-track="member-multiple-choice-continue"
        [disabled]="isSkipping || isSubmitting || isLoading"
        [ngClass]="{
          primarySection: !isLoading && !isSuccess,
          loadingSection: isLoading,
          successSection: isSuccess
        }"
        *ngIf="!action.is_prerequisite; else showPrerequisiteBtn"
      >
        <div class="primaryTitle text submit-label">
          {{ 'general.submit' | translate }}
        </div>
        <div class="primaryTitle text continue-label">
          {{ 'general.continue' | translate }}
        </div>

        <div class="loadingTitle text">
          <suzy-spinner
            class="absolute left-0 right-0 button-spinner top-3"
            size="small"
            button="true"
            [round]="7"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
        </div>

        <div class="successTitle text">
          <svg class="animated-check" viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
          </svg>
        </div>
      </button>

      <ng-template #showPrerequisiteBtn>
        <button
          class="relative px-8 overflow-hidden text-sm font-bold text-white transition ease-in-out rounded-full md:text-base w-52 bg-blue-dark h-14"
          [ngClass]="{ 'animated-btn ': isSuccess, 'load-start': isLoading }"
          [disabled]="isSkipping || isSubmitting"
          (click)="onSubmitPrerequisite()"
        >
          <suzy-spinner
            *ngIf="isLoading"
            class="absolute left-8 button-spinner top-7"
            size="small"
            button="true"
            [round]="7"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
          <svg
            *ngIf="isSuccess"
            class="absolute inline-block left-1 button-spinner top-0.5"
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="51"
            viewBox="0 0 90 91"
            fill="none"
          >
            <path
              d="M45 90.998C69.8528 90.998 90 70.8509 90 45.998C90 21.1452 69.8528 0.998047 45 0.998047C20.1472 0.998047 0 21.1452 0 45.998C0 70.8509 20.1472 90.998 45 90.998Z"
              fill="#68C97F"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M67.31 28.8067C66.6774 28.1688 65.8163 27.8101 64.9179 27.8101C64.0196 27.8101 63.1585 28.1688 62.5258 28.8067L37.4257 53.9404L26.8803 43.3613C25.9956 42.5067 24.7222 42.189 23.5397 42.5279C22.3573 42.8668 21.4454 43.8107 21.1476 45.0042C20.8499 46.1977 21.2115 47.4594 22.0962 48.314L35.0337 61.2515C35.6663 61.8893 36.5274 62.2481 37.4257 62.2481C38.3241 62.2481 39.1852 61.8893 39.8178 61.2515L67.31 33.7593C68.0014 33.1215 68.3947 32.2236 68.3947 31.283C68.3947 30.3423 68.0014 29.4445 67.31 28.8067Z"
              fill="white"
            />
          </svg>
          {{ 'general.submit' | translate }}
        </button>
      </ng-template>
    </div>
  </div>

  <!-- Form Content Starts -->
  <div
    class="flex flex-col h-full form"
    *ngIf="
      action.multiple_choice.multiple_choice_kind === multipleChoiceKind.monadic
    "
  >
    <div
      class="text-base font-medium md:text-lg md:font-semibold form-group form-filling"
    >
      <div class="form-field-container">
        <div class="action-row">
          <div class="slider">
            <div class="row-error" *ngIf="errorMessage.length">
              <span
                [innerHTML]="'grid.dragTheScrubberToGiveARating' | translate"
              ></span>
              <span class="fa fa-long-arrow-down"></span>
            </div>
            <div
              class="slider-header"
              data-track="member-scale-rank-slider-header"
            >
              <div
                [ngClass]="{ darkMode: isDarkMode }"
                *ngFor="
                  let option of action.multiple_choice.options;
                  index as i
                "
                [class.selected]="option.isSelected"
                [class.hideColor]="
                  (action.multiple_choice.options.length / 2
                    | number : '1.0-0') ===
                  i + 1
                "
                [attr.data-track]="'member-scale-rank-slider-slot-' + i"
              >
                <span>{{ i + 1 }}</span>
              </div>
            </div>
            <div class="slider-container">
              <div class="slider-fill-left"></div>
              <input
                #range
                type="range"
                min="0"
                max="{{ (action.multiple_choice.options.length - 1) * 100 }}"
                value="{{ (action.multiple_choice.options.length - 1) * 50 }}"
                [attr.data-track]="'member-scale-rank-slider'"
                (touchstart)="
                  onSliderGrab($event, action.multiple_choice.options)
                "
                (touchend)="
                  onSliderRelease($event, action.multiple_choice.options)
                "
                (mousedown)="
                  onSliderGrab($event, action.multiple_choice.options)
                "
                (input)="onSliderUpdate($event, action.multiple_choice.options)"
                (mouseup)="
                  onSliderRelease($event, action.multiple_choice.options)
                "
                (change)="
                  onSliderChange($event, action.multiple_choice.options)
                "
              />
              <span class="slider-thumb-value">{{ selectedValue }}</span>
              <div class="slider-fill-right"></div>
            </div>
          </div>
          <div class="terminal-labels" [attr.data-track]="'terminal-labels'">
            <div class="text-xs font-semibold text-black dark:text-white">
              {{ action.multiple_choice.options[0].ui_text }}
            </div>
            <div class="text-xs font-semibold text-black dark:text-white">
              {{
                action.multiple_choice.options[
                  action.multiple_choice.options.length - 1
                ].ui_text
              }}
            </div>
          </div>
        </div>
        <p
          class="flex items-center my-6 error-inline-msg text-error"
          *ngIf="errorMessage"
        >
          <svg
            class="inline-block mr-2 ltr:mr-2 rtl:ml-2"
            width="24"
            height="24"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.50004 4.04351C8.06282 4.04351 7.70837 4.39795 7.70837 4.83518V8.00185C7.70837 8.43907 8.06282 8.79351 8.50004 8.79351C8.93727 8.79351 9.29171 8.43907 9.29171 8.00185V4.83518C9.29171 4.39795 8.93727 4.04351 8.50004 4.04351ZM9.22837 10.8677C9.21104 10.8173 9.18708 10.7694 9.15712 10.7252L9.06212 10.6065C8.83447 10.3818 8.49402 10.3163 8.1992 10.4402C8.10326 10.4803 8.0149 10.5366 7.93795 10.6065C7.78976 10.7559 7.70715 10.9581 7.70837 11.1686C7.70962 11.272 7.73114 11.3742 7.7717 11.4694C7.84368 11.6683 8.00031 11.8249 8.1992 11.8969C8.39082 11.9816 8.60925 11.9816 8.80087 11.8969C8.99976 11.8249 9.15639 11.6683 9.22837 11.4694C9.26894 11.3742 9.29045 11.272 9.2917 11.1686C9.29559 11.1159 9.29559 11.0629 9.2917 11.0102C9.27807 10.9597 9.25671 10.9117 9.22837 10.8677ZM8.50004 0.0852051C4.12779 0.0852051 0.583374 3.62962 0.583374 8.00187C0.583374 12.3741 4.12779 15.9185 8.50004 15.9185C12.8723 15.9185 16.4167 12.3741 16.4167 8.00187C16.4167 5.90224 15.5826 3.88861 14.098 2.40394C12.6133 0.91928 10.5997 0.0852051 8.50004 0.0852051ZM8.50005 14.3352C5.00225 14.3352 2.16672 11.4996 2.16672 8.00185C2.16672 4.50404 5.00225 1.66851 8.50005 1.66851C11.9979 1.66851 14.8334 4.50404 14.8334 8.00185C14.8334 9.68155 14.1661 11.2925 12.9784 12.4802C11.7907 13.6679 10.1798 14.3352 8.50005 14.3352Z"
              fill="#FF5F57"
            />
          </svg>

          {{ 'unauthorized.yourAccountFlagged' | translate }}
          <a
            href="https://support.crowdtap.com/hc/en-us/articles/16762298155284"
            target="_blank"
            >{{ 'unauthorized.clickHereMission' | translate }}</a
          >.
        </p>
      </div>
    </div>

    <div
      class="relative flex items-center justify-center pb-24 md:pb-6 space-x-2 align-middle form-group form-cta"
    >
      <ng-container
        *ngIf="
          !action.prevent_skip &&
          mission.mission_kind !== missionKind.external_link
        "
      >
        <button
          type="button"
          class="text-sm font-bold md:text-base w-36 text-blue-dark skip"
          (click)="onSkip($event)"
          data-track="member-multiple-choice-skip"
          (mousemove)="onMouseMoveEvent($event)"
          [disabled]="isTrapQuestion || disableSkip || isSkipping"
          [ngClass]="{ loading: isSkipping }"
          *ngIf="!action.is_prerequisite; else showPrerequisiteBtn"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>
      </ng-container>

      <button
        class="animation-submit-btn"
        [ngClass]="{
          primarySection: !isLoading && !isSuccess,
          loadingSection: isLoading,
          successSection: isSuccess
        }"
        (mousemove)="onMouseMoveEvent($event)"
        data-track="member-multiple-choice-continue"
        [disabled]="!f.valid || isSkipping || isSubmitting || isLoading"
        (click)="onSubmit()"
      >
        <div class="primaryTitle text submit-label">
          {{ 'general.submit' | translate }}
        </div>
        <div class="primaryTitle text continue-label">
          {{ 'general.continue' | translate }}
        </div>

        <div class="loadingTitle text">
          <suzy-spinner
            class="absolute left-0 right-0 button-spinner top-3"
            size="small"
            button="true"
            [round]="7"
            [color]="'#ffffff'"
            [overlay]="false"
          >
          </suzy-spinner>
        </div>

        <div class="successTitle text">
          <svg class="animated-check" viewBox="0 0 24 24">
            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
          </svg>
        </div>
      </button>
      <ng-template #showPrerequisiteBtn>
        <button
          class="relative px-8 overflow-hidden text-sm font-bold text-white transition ease-in-out rounded-full md:text-base w-52 bg-blue-dark h-14"
          [ngClass]="{ 'animated-btn ': isSuccess, 'load-start': isLoading }"
          [disabled]="isSkipping || isSubmitting"
          (click)="onSubmitPrerequisite()"
        >
          {{ 'general.submit' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
  <!-- Form Content Ends -->
</form>
