<div *ngIf="errorMessage.length" class="error-popover">
  <div class="error-popover-inner">
    <span class="error-popover-close" (click)="removeErrorPopover()"></span>
    <span class="error-popover-msg">{{ errorMessage }}</span>
  </div>
</div>
<!-- Action Modal Starts -->
<div
  class="action-modal-container"
  [attr.data-track]="
    'member-rank-grid-col-' + cols.length + '-row-' + rows.length
  "
>
  <div class="head-container">
    <!-- <suzy-spinner *ngIf="isSubmitting"></suzy-spinner> -->
    <app-action-modal-header
      [mission]="mission"
      [metaData]="metaData"
      [disableButton]="mission?.isGlobal"
    ></app-action-modal-header>

    <!-- Action Modal Question Starts -->
    <div
      class="action-modal-question dark:bg-body-dark bg-body-light border-b-2 border-card-{{
        activeColor
      }} dark:text-white md:pt-0 md:px-24 md:py-8 p-6 pt-0 sm:px-20"
    >
      <h3
        class="text-base font-medium md:text-lg md:font-semibold lg:text-xl lg:font-medium"
        [innerHTML]="action.grid.question | markdown2html : { secure: true }"
        data-track="member-rank-grid-question-text"
      ></h3>
      <div class="hide-mobile mb-4">
        <div class="text-xs font-semibold subtitle" *ngIf="noRowsSelected">
          {{ 'grid.dragNoRowSelected' | translate }}
        </div>
        <div class="text-xs font-semibold subtitle" *ngIf="someRowsSelected">
          {{ 'grid.dragSomeRowsSelected' | translate }}
        </div>
        <div class="text-xs font-semibold subtitle" *ngIf="allRowsSelected">
          {{ 'grid.dragAllRowsSelected' | translate }}
        </div>
      </div>
      <div class="show-mobile mb-4">
        <div class="text-xs font-semibold subtitle">
          <span *ngIf="noRowsSelected">{{
            'grid.tapNoRowsSelected' | translate
          }}</span>
          <span *ngIf="someRowsSelected">{{
            'grid.tapNoRowsSelected' | translate
          }}</span>
          <span *ngIf="allRowsSelected">{{
            'grid.tapALlRowsSelected' | translate
          }}</span>
          <span class="terminals"
            >1 = {{ lowestTerminalValue }}, {{ rows.length }} =
            {{ highestTerminalValue }}</span
          >
        </div>
      </div>
      <suzy-preroll-secondary-trigger
        *ngIf="hasPreroll && prerollViewed"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
        (imagePrerollClick)="onViewPrerollImage($event)"
        (linkPrerollClick)="onViewPrerollLink()"
      >
      </suzy-preroll-secondary-trigger>
    </div>
    <!-- Action Modal Question Ends -->
  </div>
  <!-- Action Modal Body Starts -->
  <div
    class="flex-1 p-6 sm:px-20 md:px-24 md:py-8 dark:text-white action-body-container"
  >
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent($event)"
      *ngIf="hasPreroll && !prerollViewed"
      [preRollKind]="preRollKind"
      [preRollCaption]="preRollCaption"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    >
    </suzy-preroll>

    <suzy-action-forms-grid-rank
      [action]="action"
      [cols]="cols"
      [rows]="rows"
      [rowErrors]="rowErrors"
      [allChoices]="allChoices"
      [isLoading]="isLoading"
      [isSuccess]="isSuccess"
      [colErrors]="colErrors"
      [lowestTerminalValue]="lowestTerminalValue"
      [highestTerminalValue]="highestTerminalValue"
      [disableSkip]="
        disableSkip || mission?.mission_kind === missionKind.external_link
      "
      [isSubmitting]="isSubmitting"
      (timeToActionUpdate)="onTimeToActionUpdate($event)"
      (mouseEvent)="onMouseMoveEvent($event)"
      (updateMetaDataValidationCount)="onUpdateMetaDataValidationCount()"
      (skip)="onSkip($event)"
      (grFormSubmit)="onSubmit($event)"
      [(errorMessage)]="errorMessage"
      *ngIf="!hasPreroll || (hasPreroll && prerollViewed)"
      [disableButton]="mission?.isGlobal"
      [isSkipping]="isSkipping"
      (trackError)="errorChange($event)"
    ></suzy-action-forms-grid-rank>
  </div>
  <!-- Action Modal Body Ends -->
</div>
<!-- Action Modal Ends -->
