<div class="action-modal-container">
  <suzy-spinner *ngIf="isSubmitting"></suzy-spinner>

  <app-action-modal-header
    [title]="'Choose Address'"
    [mission]="mission"
  ></app-action-modal-header>
  <!-- Sampling Action Qestion Stars -->
  <div class="sampling-action-question">
    <h3>{{ 'accept.samplingHeader' | translate }}</h3>
    <p>{{ 'accept.samplingDescription' | translate }}</p>
  </div>
  <!-- Sampling Action Qestion Ends -->

  <!-- Action Modal Body Starts -->
  <div class="modal-body action-modal-body">
    <form novalidate="">
      <div class="form">
        <div class="form-group form-filling">
          <ul class="review-list">
            <li class="item">
              <div class="text">
                <h4 class="number">
                  <div></div>
                  {{ 'accept.productReview' | translate }}
                </h4>
                <p>{{ 'accept.leaveAReview' | translate }}</p>
                <p>
                  <i>{{ 'accept.leaveAReviewUploadScreenshot' | translate }}</i>
                </p>
              </div>
            </li>
            <li class="item">
              <div class="text">
                <h4 class="number">
                  <div></div>
                  {{ 'accept.report' | translate }}
                </h4>
                <p *ngIf="mission.sampling?.deadline_utc">
                  {{ 'accept.samplingDeadlineDescription' | translate }}
                  {{ mission.sampling.deadline_utc | date : 'MMM/d/yyyy' }}
                </p>
              </div>
            </li>
          </ul>
          <div class="row mt-2">
            <div class="form-group">
              <input
                type="checkbox"
                name="op-in"
                id="op-in"
                [(ngModel)]="response"
              />
              <label class="custom-checkbox" for="op-in">
                {{ action.accept?.question }}
                <span class="check-icon"></span>
              </label>
              <p class="terms">
                Click
                <a routerLink="/terms-of-service" target="_blank">here</a> to
                read Crowdtap's Terms of Service
              </p>
            </div>
          </div>
        </div>
        <div class="form-group form-cta">
          <div class="row">
            <div class="col-6" *ngIf="allowSkipping">
              <button
                class="btn form-btn form-btn-secondary text-uppercase"
                (click)="skip($event)"
                [disabled]="disableSkip"
              >
                {{ 'general.skip' | translate }}
              </button>
            </div>
            <div
              [ngClass]="{ 'col-12': !allowSkipping, 'col-6': allowSkipping }"
            >
              <button
                class="btn form-btn form-btn-primary form-btn-with-arrow text-uppercase"
                [disabled]="isSubmitting || !response"
                (click)="onSubmit()"
              >
                {{ 'general.continue' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
