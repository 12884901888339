import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'biometric-policy-v20230719',
  templateUrl: './biometric-policy-v20230719.component.html',
  styleUrls: ['./biometric-policy-v20230719.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BiometricPolicyV20230719Component implements OnInit {
  showPhotoVerify = false;
  verification: any;
  participant = false;
  submitting = false;

  constructor() {}

  ngOnInit(): void {}

  toggle(property: string) {
    this[property] = !this[property];
  }
}
